import { Component } from "react";
import NavBar from "../components/NavBar";
import colors from "../configuration/colors.config"
import firebase from 'firebase';
import {
    Input,
    Row,
    Col,
    Label
} from "reactstrap";

const firebaseConfig = {
    apiKey: "AIzaSyDBWrc9hPXQqVKtQN3DnHph1eCtGCiUfog",
    authDomain: "checkroom-5b90c.firebaseapp.com",
    projectId: "checkroom-5b90c",
    storageBucket: "checkroom-5b90c.appspot.com",
    messagingSenderId: "519160218404",
    appId: "1:519160218404:web:a47814a506b9b8601ae9b1"
};

firebase.initializeApp(firebaseConfig)
var database = firebase.database();

class Main extends Component {


    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice: false,
            dataTime : "2022-08-15"
        }
    }

    componentDidMount() {

        document.body.style.backgroundColor = colors.azul;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        this.setState({
            isMobileDevice: isMobileDevice,
        });

    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} />
                {/* <Row
                    style={{
                        margin: "100px"
                    }}
                >
                    <Col>
                        <Input
                            type="datetime-local"
                            onChange={(e)=>{
                                let {value} = e.target
                                this.setState({
                                    dataTime : value
                                });

                            }}
                        // value=""
                        />
                    </Col>
                    <Col>
                        <Label>
                            {this.state.dataTime}
                        </Label>
                    </Col>

                </Row> */}
            </>
        );
    }


}

export default Main;