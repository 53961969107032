import axios from 'axios';
import API from '../network/API'
import swal2 from 'sweetalert2';

const mensajeError = (error) => {

    swal2.fire({
        icon: 'error',
        title: 'Error de Red',
        text: error,
        //timer: "5000",
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true
    }).then(() => {
        //window.location.href="/";
    });

}

const movimientos_list = (fecha, callback) => {
    //// console.log(fecha);
    axios({
        method: "POST",
        url: API + "/movimientos/movimientos_list",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            fecha
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}


const entradas_list = async (fecha, callback) => {
    //// console.log(fecha);
    // axios({
    //     method: "POST",
    //     url: API + "/movimientos/entradas_list",
    //     headers: {
    //         //token : cookies.get('token')
    //         "Access-Control-Allow-Origin": "*"
    //     },        
    //     data:{ 
    //         fecha
    //     }
    // }).then(response => {
    //     let data = response.data;

    //     callback(data);

    // }).catch((error) => {
    //     mensajeError(error);
    // });

    let registros = await axios({
        method: "POST",
        url: API + "/movimientos/entradas_list",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            fecha
        }
    });
    // console.log(registros);

    return registros.data


}

const dispositivo_obtener_configuracion = (IdDispositivo, callback) => {
    //// console.log(fecha);
    axios({
        method: "POST",
        url: API + "/movimientos/dispositivo_obtener_configuracion",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            IdDispositivo
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const dispositivo_cambiar_configuracion = (dispositivo, callback) => {
    //// console.log(fecha);
    axios({
        method: "POST",
        url: API + "/movimientos/dispositivo_cambiar_configuracion",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            dispositivo
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const movimientosModel = {
    movimientos_list,
    entradas_list,
    dispositivo_obtener_configuracion,
    dispositivo_cambiar_configuracion
}

export default movimientosModel;