import React, { useState } from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Navbar,
    Nav,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,

} from 'reactstrap';
//import IconButton from "@material-ui/core/IconButton";
import colors from "../configuration/colors.config"
import {
    List,
    Menu,
    Person,
    Apps,
    ExitToApp,
    ViewCarousel,
    Group,
    AccountCircle,
    Memory,
    MeetingRoom,
    BarChart,
} from '@material-ui/icons';
//import historialModel from "../Model/Hisrorial.modal";

import Cookies from 'universal-cookie';
const cookies = new Cookies();


const NavBarMovil = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const [styleDropdownToggle] = useState({
        backgroundColor: colors.azul_oscuro,
        color: colors.blanco,
        fontWeight: "bold",
        border: "none",
        boxShadow: "none"
    });
    const [styleIcon] = useState({
        marginTop: "-5px",
        marginRight: "5px",
        color: colors.rosado
    });

    // const [stylesubIcon] = useState({
    //     marginTop: "-5px",
    //     marginRight: "5px",
    //     color: colors.blanco
    // });

    const [styleDropdownItem] = useState({
        color: colors.azul_oscuro
    })


    const open = () => setIsOpen(!isOpen);




    const logOut = () => {
        cookies.remove('usuario', { path: "/" });
        window.location.href = "/";

    }




    return (

        <div

        >
            <Navbar expand="md" style={{
                backgroundColor: colors.azul_oscuro,
                height: "62px",
                width: "100%"
            }}>
                <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                </Nav>
                <Button
                    //color="primary"
                    style={{
                        backgroundColor: colors.blanco,
                        borderColor: colors.azul,
                        color: "red",
                        boxShadow: "none",
                        fontWeight: "bold"
                    }}
                    onClick={open}
                >
                    <Menu style={{ color: "#016390" }} />
                </Button>
                <Offcanvas
                    toggle={open}
                    isOpen={isOpen}
                    direction="end"
                    style={{
                        backgroundColor: colors.azul_oscuro
                    }}
                >
                    <OffcanvasHeader toggle={open}>
                        <h2
                            style={{
                                color: colors.blanco,
                                fontWeight: "bold"
                            }}
                        >
                            Menu
                        </h2>
                    </OffcanvasHeader>

                    <OffcanvasBody>
                        <Nav vertical>
                            <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={styleDropdownToggle}

                                >
                                    <List style={styleIcon} />Dispositivos y Usuarios
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem
                                        style={styleDropdownItem} href="/tabla/usuarios"
                                    >
                                        <AccountCircle style={styleIcon} />Usuarios
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem style={styleDropdownItem} href="/tabla/dispositivos">
                                        <Memory style={styleIcon} />Dispositivos
                                    </DropdownItem>

                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={styleDropdownToggle}

                                >
                                    <ViewCarousel style={styleIcon} />Temperatura y Humedad
                                </DropdownToggle>
                                <DropdownMenu>

                                    <DropdownItem style={styleDropdownItem} href="/grafica/temperatura_y_humedad">
                                        <BarChart style={styleIcon} />Graficar registros
                                    </DropdownItem>
                                    {/* <DropdownItem divider />
                                    <DropdownItem
                                        style={styleDropdownItem}
                                    >
                                        <List style={styleIcon} />Panel de configuracion
                                    </DropdownItem> */}

                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={styleDropdownToggle}
                                >
                                    <Group style={styleIcon} />Movimientos
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href='/tabla/movimientos'>
                                        <List style={styleIcon} /> Mostrar registros
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>

                            <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    caret
                                    style={styleDropdownToggle}
                                >
                                    <MeetingRoom style={styleIcon} />Entradas
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem href='/tabla/entrada'>
                                        <List style={styleIcon} /> Mostrar registros
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <UncontrolledDropdown
                                className="me-2"
                                direction="down"
                                setActiveFromChild
                            >
                                <DropdownToggle
                                    //

                                    style={styleDropdownToggle}
                                >
                                    <Apps style={styleIcon} /> App
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => logOut()}>
                                        <ExitToApp style={styleIcon} /> Cerrar Sesión
                                    </DropdownItem>

                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>

                    </OffcanvasBody>

                </Offcanvas>
            </Navbar>
        </div>


    );
}

export default NavBarMovil;