
import React, { useState } from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Navbar,
    Nav
} from 'reactstrap';
import {
    List,    
    Apps,
    Person,
    ExitToApp,
    ViewCarousel,
    MeetingRoom,
    Memory,
    BarChart,
    Group
} from '@material-ui/icons';
//import historialModel from "../Model/Hisrorial.modal";
import Cookies from 'universal-cookie';
import colors from "../configuration/colors.config"
const cookies = new Cookies();


const NavBarComputer = (props) => {
    // const [isOpen, setIsOpen] = useState(false);
    const [styleDropdownToggle] = useState({
        backgroundColor: colors.azul_oscuro,
        color: colors.blanco,
        fontWeight: "bold",
        border: "none",
        boxShadow: "none"
    });
    const [styleIcon] = useState({
        marginTop: "-5px",
        marginRight: "5px",
        color: colors.rosado
    });

    // const [stylesubIcon] = useState({
    //     marginTop: "-5px",
    //     marginRight: "5px",
    //     color: colors.blanco
    // });

    const [styleDropdownItem] = useState({
        color: colors.azul_oscuro
    })
    //const open = () => setIsOpen(!isOpen);

    const logOut = () => {

        // let usuario = cookies.get('usuario', { path: "/" })
        // let dataMovimiento = {
        //     IdMovimiento: 2,
        //     IdUsuario: usuario.IdUsuario
        // }

        // historialModel.historial_nuevo_movimiento(dataMovimiento, (data) => { });
        cookies.remove('usuario', { path: "/" });
        // if (props.usuario.IdUsuarioTipo === 1) {
        //     window.location.href = "/login-administrador";
        // } else {
        //     window.location.href = "/";
        // }
        window.location.href = "/";
    }




    return (
        <>
            <div
                style={{
                    position: "fixed",
                    top: 0,
                    width: "100%",
                    zIndex: "100"
                }}
            >

                <Navbar
                    expand="md"
                    style={{

                        backgroundColor: colors.azul_oscuro,
                        height: "62px"
                    }}>
                    <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                        {/* <NavItem>
                            <NavLink style={{ fontWeight: "bold", color: "#016390" }} href="/"><Home style={styleIcon} />Cursos</NavLink>
                        </NavItem> */}
                        
                        <UncontrolledDropdown
                            className="me-2"
                            direction="down"
                            setActiveFromChild
                        >
                            <DropdownToggle
                                caret
                                style={styleDropdownToggle}

                            >
                                <List style={styleIcon} />Dispositivos
                            </DropdownToggle>
                            <DropdownMenu>
                                {/* <DropdownItem
                                    style={styleDropdownItem} href="/tabla/usuarios"
                                >
                                    <Group style={styleIcon} />Listar Usuarios
                                </DropdownItem> */}
                                {/* <DropdownItem divider /> */}
                                <DropdownItem style={styleDropdownItem} href="/tabla/dispositivos">
                                    <Memory style={styleIcon} />Listar Dispositivos
                                </DropdownItem>

                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <UncontrolledDropdown
                            className="me-2"
                            direction="down"
                            setActiveFromChild
                        >
                            <DropdownToggle
                                caret
                                style={styleDropdownToggle}

                            >
                                <List style={styleIcon} />Usuarios
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    style={styleDropdownItem} href="/tabla/usuarios"
                                >
                                    <Group style={styleIcon} />Listar Usuarios
                                </DropdownItem>
                                {/* <DropdownItem divider /> */}
                                {/* <DropdownItem style={styleDropdownItem} href="/tabla/dispositivos">
                                    <Memory style={styleIcon} />Listar Dispositivos
                                </DropdownItem> */}

                            </DropdownMenu>
                        </UncontrolledDropdown>


                        <UncontrolledDropdown
                            className="me-2"
                            direction="down"
                            setActiveFromChild
                        >
                            <DropdownToggle
                                caret
                                style={styleDropdownToggle}

                            >
                                <ViewCarousel style={styleIcon} />Temperatura y Humedad
                            </DropdownToggle>
                            <DropdownMenu>

                                <DropdownItem style={styleDropdownItem} href="/grafica/temperatura_y_humedad">
                                    <BarChart style={styleIcon} />Graficar registros
                                </DropdownItem>
                                {/* <DropdownItem divider />
                                <DropdownItem
                                    style={styleDropdownItem}
                                >
                                    <List style={styleIcon} />Panel de configuracion
                                </DropdownItem> */}

                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown
                            className="me-2"
                            direction="down"
                            setActiveFromChild
                        >
                            <DropdownToggle
                                caret
                                style={styleDropdownToggle}
                            >
                                <Group style={styleIcon} />Movimientos
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem href='/tabla/movimientos'>
                                    <List style={styleIcon} /> Mostrar registros
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <UncontrolledDropdown
                            className="me-2"
                            direction="down"
                            setActiveFromChild
                        >
                            <DropdownToggle
                                caret
                                style={styleDropdownToggle}
                            >
                                <MeetingRoom style={styleIcon} />Entradas
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem href='/tabla/entrada'>
                                    <List style={styleIcon} /> Mostrar registros
                                </DropdownItem>


                            </DropdownMenu>
                        </UncontrolledDropdown>


                    </Nav>

                    <UncontrolledDropdown>

                        <DropdownToggle
                            //caret
                            style={styleDropdownToggle}
                        >
                            <Apps />
                        </DropdownToggle>
                        <DropdownMenu>
                            {/* <DropdownItem href='/login-administrador'>
                                <Person style={styleIcon} /> Mi Perfil
                            </DropdownItem> */}
                            {/* <DropdownItem divider /> */}
                            <DropdownItem onClick={() => logOut()}>
                                <ExitToApp style={styleIcon} /> Cerrar Sesión
                            </DropdownItem>

                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Navbar>
            </div>
        </>
    );
}

export default NavBarComputer;