import { useEffect } from "react";
import NavBarComputer from "./NavBarComputer";
import NavBarMovil from "./NavBarMovil";
import userModer from "../models/user.model"

const NavBar = (props) => {


    useEffect(() => {
        userModer.getUserCookies();
    }, []);


    return (
        <>
            {!props.isMobileDevice ?
                <NavBarComputer />
                :
                <NavBarMovil />
            }
        </>
    );
}

export default NavBar;