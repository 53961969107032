import { Component } from "react";
import NavBar from "../components/NavBar";
import colors from "../configuration/colors.config"
import Table from "react-data-table-component";
import dispositivoModel from "../models/chapa.model";
import gifLoading from "../multimedia/gif/loading.gif";
import {
    Button,
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    Spinner
} from "reactstrap"
import {
    Cached,
} from '@material-ui/icons';
// import movimientosModel from "../models/movimisntos.model";
import { Toast } from "../models/Toast.model";
import time from "../models/time.model";
import date from "../models/date.model";
import firebase from 'firebase';
import Swal from "sweetalert2";
import chapaModel from "../models/chapa.model";
var database = firebase.database();

class TablaEntrada extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataColumd: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Fecha</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={date.format_date_string(row.fecha)} >{date.format_date(row.fecha)}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Hora</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={time.format_am_pm(row.hora)} >{time.format_am_pm(row.hora)}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Lugar</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.descripcionUbicacion} >{row.ubicacion}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Descripcion</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.ubicacion} >{row.descripcionUbicacion}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Usuario</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.nombre + " " + row.paterno + " " + row.materno} >{row.username}</abbr>,
                    sortable: true
                },
            ],
            dataList: [],
            listChapas: [],
            chapa: {
                IdChapa: 0,
                fecha: ""
            },
            isMobileDevice: false,
            isCheck: true,
            fechaRegistros: "",
            isLoad: false,
        }
    }
    agregarPaso = (numeroPaso) => {
        dispositivoModel.pasos_nueva_guella_agregar_paso(numeroPaso, (data) => { });
    }

    componentDidMount() {



        this.cargarDataPge();

    }


    cargarDataPge = async () => {

        document.body.style.backgroundColor = colors.azul;

        document.body.style.backgroundColor = colors.azul;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);





        database.ref(`tablaEntradas/`).on('value', async (snapshot) => {
            console.log(snapshot.exists());
            if (snapshot.exists()) {
                let fechaRegistros = await time.getFecha();

                let result = await chapaModel.chapa_list();
                let listChapas = result.dataList;
                // console.log(listChapas);
                result = await chapaModel.entradas_list({ IdChapa: 0, fecha: fechaRegistros });
                // console.log(result);
                let { dataList } = result

                await this.setState({
                    isMobileDevice,
                    fechaRegistros,
                    chapa: {
                        ...this.state.chapa,
                        fecha: fechaRegistros
                    },
                    listChapas,
                    dataList
                });
            }
        });

    }

    sleep(seconds) {
        return new Promise(resolve => setTimeout(resolve, (seconds * 1000)));
    }
    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} />
                <Row
                    style={{
                        marginTop: this.state.isMobileDevice ? "15px" : "100px",
                        marginLeft: this.state.isMobileDevice ? "5%" : "20px",
                        marginRight: this.state.isMobileDevice ? "5%" : "20px",
                        backgroundColor: colors.blanco,
                        height: "80px",
                        borderRadius: "8px",
                    }}
                >
                    <Col>
                        {
                            this.state.isLoad ?
                                <Label
                                    style={{
                                        marginTop: "25px",
                                        marginLeft: "10px",
                                        fontWeight: "bold",
                                        fontSize: "18px"
                                    }}
                                >
                                    {this.state.isMobileDevice ?
                                        "Cargando..."
                                        :
                                        "Cargando tabla, por favor espere..."
                                    }
                                </Label>
                                :
                                null
                        }

                    </Col>
                    <Col
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <abbr
                            title="Refrescar tabla"
                            style={{ textDecoration: "none" }}
                        >
                            <Button

                                onClick={async () => {
                                    await this.setState({
                                        isLoad: true,
                                    });
                                    let result = await chapaModel.entradas_list(this.state.chapa);
                                    let { dataList } = result;

                                    await this.setState({
                                        dataList,
                                        isLoad: false,
                                    });

                                }}
                                style={{
                                    color: colors.blanco,
                                    backgroundColor: colors.azul_oscuro,
                                    borderColor: colors.azul_oscuro,
                                    marginTop: "20px",
                                    marginRight: "10px"
                                }}
                            >

                                {
                                    this.state.isLoad ?
                                        <Spinner
                                            style={{
                                                marginTop: "",
                                                height: '1.4rem',
                                                width: '1.4rem'
                                                // fontSize:"25px"
                                            }}
                                        />
                                        :
                                        <Cached />
                                }
                            </Button>
                        </abbr>
                    </Col>

                </Row>

                <div
                    style={{
                        color: colors.blanco,
                        // borderRadius: "8px",
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginTop: this.state.isMobileDevice ? "20px" : "10px",
                        height: "90%",
                    }}
                >

                    <div
                        style={{
                            backgroundColor: colors.blanco,
                            borderRadius: "8px 8px 0px 0px",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }}
                    >
                        <Row
                            style={{
                                marginLeft: "10px",
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "left",
                                    marginTop: "10px"
                                }}
                                md="2"
                            >
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.azul_oscuro
                                        }}
                                    >
                                        Chapa selecionada
                                    </Label>
                                    {/* <Input
                                        style={{
                                            width: this.state.isMobileDevice ? "90%" : "250px",
                                        }}
                                        id=""
                                        name="fechaRegistros"
                                        placeholder=""
                                        type="date"
                                        value={this.state.fechaRegistros}
                                        onChange={(e) => {
                                            let { value } = e.target;
                                            //// console.log(value);
                                            this.setState({
                                                fechaRegistros: value,
                                            });

                                            // this.obtenerListMovimientos(value);

                                        }}
                                    /> */}
                                    <Input
                                        type="select"
                                        name="fecha"
                                        value={this.state.chapa.IdChapa}
                                        style={{
                                            width: this.state.isMobileDevice ? "90%" : "250px",
                                        }}
                                        onChange={async (e) => {
                                            let { value } = e.target;
                                            console.log(value);
                                            await this.setState({
                                                chapa: {
                                                    ...this.state.chapa,
                                                    IdChapa: value
                                                },
                                                isLoad: true,
                                            });



                                            let result = await chapaModel.entradas_list(this.state.chapa);
                                            let { dataList } = result
                                            await this.setState({
                                                dataList,
                                                isLoad: false,
                                            });
                                        }}
                                    >

                                        <option value={0}>-Todos-</option>
                                        {this.state.listChapas.map((chapa) => {
                                            return (
                                                <option
                                                    value={chapa.IdChapa}
                                                    title={chapa.ubicacion}
                                                >
                                                    {chapa.codigo}
                                                </option>
                                            );


                                        })}
                                    </Input>

                                </FormGroup>
                            </Col>
                            <Col
                                style={{
                                    textAlign: "left",
                                    marginTop: "10px"
                                }}
                                md="2"
                            >
                                <FormGroup>
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.azul_oscuro
                                        }}
                                    >
                                        Fecha de los registros
                                    </Label>
                                    <Input
                                        style={{
                                            width: this.state.isMobileDevice ? "90%" : "250px",
                                        }}
                                        id=""
                                        name="fechaRegistros"
                                        placeholder=""
                                        type="date"
                                        value={this.state.chapa.fecha}
                                        onChange={async (e) => {
                                            let { value } = e.target;
                                            //// console.log(value);
                                            await this.setState({
                                                chapa: {
                                                    ...this.state.chapa,
                                                    fecha: value
                                                },
                                                isLoad: true,
                                            });



                                            let result = await chapaModel.entradas_list(this.state.chapa);
                                            let { dataList } = result
                                            await this.setState({
                                                dataList,
                                                isLoad: false,
                                            });

                                        }}
                                    />


                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                    <div
                        style={{
                            // borderRadius: "0px 0px 8px 8px",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }}
                    >


                        {
                            this.state.isLoad ?
                                <Row
                                    style={{
                                        marginLeft: this.state.isMobileDevice ? "0px" : "0px",
                                        marginRight: this.state.isMobileDevice ? "0px" : "0px",
                                        backgroundColor: colors.blanco,
                                        // height: "80px",
                                        borderRadius: "0px 0px 8px 8px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >
                                        <img
                                            style={{
                                                marginTop: "30px",
                                                marginBottom: "50px"
                                            }}
                                            src={gifLoading}
                                            height={this.state.isMobileDevice ? 100 : 400}
                                        />
                                    </Col>
                                </Row>
                                :
                                <Table
                                    columns={this.state.dataColumd}
                                    data={this.state.dataList}
                                    title={this.state.isMobileDevice ? "Registros" : "Entradas del " + date.format_date_string(this.state.chapa.fecha, { firstMayus: false })}
                                    noDataComponent={<p style={{ fontWeight: "bold" }}>No hay registros guardados</p>}
                                    pagination={this.state.isCheck ? true : false}
                                    dense
                                />
                        }


                    </div>
                </div>
            </>
        );
    }

}

export default TablaEntrada;