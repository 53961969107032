import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Main from "./pages/Main";
import GraficaTyH from  "./pages/GraficaTyH";
import TablaMovimientos from "./pages/TablaMovimientos";
import TablaEntrada from "./pages/TablaEntrada";
import TableDispositivos from './pages/TableDispositivos'
import TablaUsuarios from "./pages/TableUsuario";
import LoginV2 from "./pages/LoginV2";

const App = () =>  {
  return (
    <>
      <Router>
        <Routes>          

          {/* <Route exact path="/" element={<Login/>} />   */}
          <Route exact path="/" element={<LoginV2/>} />   
          <Route exact path="/main" element={<Main/>} /> 
          <Route exact path="/grafica/temperatura_y_humedad" element={<GraficaTyH/>} />      
          <Route exact path="/tabla/movimientos" element={<TablaMovimientos/>} />    
          <Route exact path="/tabla/entrada" element={<TablaEntrada/>} />      
          <Route exact path="/tabla/dispositivos" element={<TableDispositivos/>} />  
          <Route exact path="/tabla/usuarios" element={<TablaUsuarios/>} />            
        </Routes>
      </Router>
    </>
  );
}



export default App;