import axios from 'axios';
import API from '../network/API'
import swal2 from 'sweetalert2';
import userMiddleware from "../middleware/user.middleware";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const mensajeError = (error) => {

    swal2.fire({
        icon: 'error',
        title: 'Error de Red',
        text: "No se pudo conectar con el servidor",
        //timer: "5000",
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,
        footer: `<label>${error}</label>`    

    }).then(() => {
        //window.location.href="/";
    });

}


const login = (usuario, callback) => {
    console.log(usuario);
    // usuario = {
    //     ...usuario,
    //     password :  userMiddleware.cifrarPassword(usuario.password),
    // }
    console.log(usuario);
    axios({
        method: "POST",
        url: API + "/user/login",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            username: usuario.username,
            password: userMiddleware.cifrarPassword(usuario.password),
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const usuario_list_por_acceso_dispositivo = async () => {
    try {
        let registros = await axios({
            method: "GET",
            url: API + "/user/usuario_list_por_acceso_dispositivo",
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
        });
        return registros.data;
    } catch (error) {
        mensajeError(error);
    }
}


const usuario_enrolados_chapa = async (IdChapa) => {
    try {
        let registros = await axios({
            method: "GET",
            url: API + "/user/usuario_enrolados_chapa/"+IdChapa,
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
        });
        return registros.data;
    } catch (error) {
        mensajeError(error);
    }
}

const usuarios_listar = async () => {
    try {
        let registros = await axios({
            method: "GET",
            url: API + "/user/usuarios_listar",
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
        });
        return registros.data;
    } catch (error) {
        mensajeError(error);
    }
}

const usuario_quitar_acceso_chapa = async (data) => {
    try {
        let registros = await axios({
            method: "POST",
            url: API + "/user/usuario_quitar_acceso_chapa",
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
            data : {
                data
            }
        });
        return registros.data;
    } catch (error) {
        mensajeError(error);
    }
}


const usuarios_posibles_enrolar_chapa = async (IdChapa) => {
    try {
        let registros = await axios({
            method: "GET",
            url: API + "/user/usuarios_posibles_enrolar_chapa/"+IdChapa,
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
        });
        return registros.data;
    } catch (error) {
        mensajeError(error);
    }
}

const usuario_actualizar = (usuario, callback) => {


    axios({
        method: "PUT",
        url: API + "/user/update_user",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            usuario
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const usuario_crear = (usuario, callback) => {


    usuario = {
        ...usuario,
        password: userMiddleware.cifrarPassword(usuario.password),
    }
    console.log(usuario);
    axios({
        method: "POST",
        url: API + "/user/create_user",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            usuario
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}


const usuario_eliminar = (IdUsuario, callback) => {

    console.log(IdUsuario);

    axios({
        method: "DELETE",
        url: API + "/user/delete_user/" + IdUsuario,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}
const usuario_negar_acceso = (IdUsuario, callback) => {

    console.log(IdUsuario);

    axios({
        method: "PUT",
        url: API + "/user/usuario_negar_acceso/" + IdUsuario,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const getUserCookies = async () =>{
    let user = cookies.get("user",{ path: "/" });
    // console.log(user);
    if(!user){
        document.body.innerHTML = "<body></body>"
        await swal2.fire({
            icon: 'warning',
            title: 'Atencion',
            text: "Tienes que inisiar sesion para poder usar este sistema web",
            timer: "10000",
            confirmButtonColor: '#016390',
            confirmButtonText: 'Okey',
            allowEscapeKey: true,
            timerProgressBar: true
        }).then((value) => {
            if(value.isConfirmed){
                window.location.href="/";
            }
            
        });
        window.location.href = "/";
    }

}

const TyHModel = {
    login,
    usuario_list_por_acceso_dispositivo,
    usuario_actualizar,
    usuario_crear,
    usuario_eliminar,
    usuario_negar_acceso,
    usuarios_listar,
    usuario_enrolados_chapa,
    usuario_quitar_acceso_chapa,
    usuarios_posibles_enrolar_chapa,
    getUserCookies,
}

export default TyHModel;