import { Component } from "react";
import swal from 'sweetalert2';
import {
    AccountCircle,
    Lock,
    VisibilityOff,
    Visibility,
} from '@material-ui/icons';
import IconButton from "@material-ui/core/IconButton";
import imgLogo from "../multimedia/Data and settings_Two Color.svg";
import colors from "../configuration/colors.config"
import {
    Row,
    Col,
    Label,
    Button,
    Input,
} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import Cookies from "universal-cookie";
import userModel from "../models/user.model";

import img from "../multimedia/fff.svg"

const cookies = new Cookies();
const mixinConfig = {
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 60000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', swal.stopTimer)
        toast.addEventListener('mouseleave', swal.resumeTimer)
    }
}

const Toast = swal.mixin(mixinConfig);
    
class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice: false,
            inputType: "password",
            usuario: {
                username: "",
                password: "",
            },
            isVisibilityPassword: false,
            screenHeight: 0,
            screenWidth: 0,
            indice: 0,
            message: [
                {
                    label: "",
                    color: ""
                },
                {
                    label: "Iniciando sesion...",
                    color: colors.gris
                },
                {
                    label: "Faltan datos por poner",
                    color: colors.naranjaWarning
                },
                {
                    label: "Acceso denegado",
                    color: colors.rojo
                }
            ]
        }
    }



    componentDidMount() {
        // eslint-disable-next-line no-restricted-globals
        let screenHeight = screen.height.toString();
        // eslint-disable-next-line no-restricted-globals
        let screenWidth = screen.width.toString();
        console.log(screenHeight);
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);
        //Azul
        // document.body.style.backgroundImage = "radial-gradient(circle at 50% 49.62%, #8c6bff 0, #7a61ff 50%, #6658f7 100%)";

        document.body.style.backgroundImage =
            !isMobileDevice ?
                // "radial-gradient(circle at 20.33% 20.33%, #384875 0, #12192b 50%, #000000 100%)"
                "radial-gradient(circle at 36.27% 101.23%, #c18bff 0, #a076ff 25%, #7a61ff 50%, #4b4def 75%, #003de0 100%)"
                // "background-image: radial-gradient(circle at 87.5% 12.5%, #c18bff 0, #a076ff 25%, #7a61ff 50%, #4b4def 75%, #003de0 100%)"
                :
                "radial-gradient(circle at 17.39% 34.79%, #ffffff 0, #ffffff 25%, #ffffff 50%, #fcf6f8 75%, #f9edf2 100%)";
        // "radial-gradient(circle at 36.27% 101.23%, #c18bff 0, #a076ff 25%, #7a61ff 50%, #4b4def 75%, #003de0 100%)"


        this.setState({
            isMobileDevice,
            screenHeight,
            screenWidth
        });

    }
    fucusElement = (Id) => {
        document.getElementById(Id).focus();
    }

    handleChangeUsuario = async (e) => {

        let { name, value } = e.target;
        // console.log(name, value);
        await this.setState({
            usuario: {
                ...this.state.usuario,
                [name]: value,
            },
        });

        await console.log(this.state.usuario);

    }



    iniciarSesion = async () => {

        if (!this.state.isMobileDevice) {
            Toast.fire({
                icon: 'info',
                text: "Logueando Usuario",
                timer: 50000
            });
        } else {
            this.setState({
                indice: 1
            });
        }


        // setTimeout(()=>
        // window.location.href = '/main'
        // ,2000);



        if (this.state.usuario.username.length === 0 || this.state.usuario.password.length === 0) {

            if (!this.state.isMobileDevice) {
                Toast.fire({
                    icon: 'warning',
                    text: "Faltan datos para poder loguearte",
                    // position : this.state.isMobileDevice ? "b" : "top-end"   
                    timer: 50000
                });
            } else {
                this.setState({
                    indice: 2
                });
            }

            this.setState({
                input: {
                    username: this.state.usuario.username.length === 0 ? true : false,
                    password: this.state.usuario.password.length === 0 ? true : false,
                }
            });

            if (this.state.usuario.username.length === 0) {
                this.fucusElement("IdIptUsername");
            } else {
                this.fucusElement("IdIptPassword");
            }

        } else {

            if(!this.state.isMobileDevice){
                Toast.fire({
                    icon: 'info',
                    text: "Iniciando Sesion"
                });
            }else{

            }
           

            await userModel.login(this.state.usuario, async (data) => {

                // console.log(data);

                let { status, user } = data;


                if (status === 200) {
                    cookies.set("token", user.token, { path: "/" });
                    cookies.set("user", user, { path: "/" });

                    window.location.href = "/main"


                } else {
                    if (!this.state.isMobileDevice) {
                        swal.fire({
                            icon: "warning",
                            title: "Acceso Denegado",
                            text: "Nombre de usuario y/o contraseña incorrectos",
                            timer: "60000",
                            confirmButtonColor: "radial-gradient(circle at 4.07% 23.48%, #c88fff 0, #a479ff 25%, #7a61ff 50%, #444bed 75%, #003add 100%)",
                            confirmButtonText: 'Okey',
                            allowEscapeKey: true,
                            timerProgressBar: true,

                        });
                    } else {
                        this.setState({
                            indice: 3
                        });
                    }

                }

            });
        }

    }

    render() {
        return (
            <>

                {!this.state.isMobileDevice ?
                    <Row
                        style={{
                            borderRadius: "8px 0px 0px 8px",
                            // backgroundImage: "linear-gradient(180deg, #ae7fff 0, #7a61ff 50%, #3046e9 100%)",
                            backgroundImage: "radial-gradient(circle at 20.33% 20.33%, #384875 0, #12192b 50%, #000000 100%)",
                            height: "650px",
                            marginLeft: "10%",
                            marginRight: "10%",
                            marginTop: "3%",
                            marginBottom: "10%"
                        }}
                    >
                        <Col
                            style={{
                                width: "100%",
                                height: "100%",
                                // backgroundColor: "blue"
                            }}
                        >
                            <Row
                                style={{
                                    marginTop: "10px"
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <Label
                                        style={{
                                            color: colors.blanco,
                                            fontSize: "48px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Check Office
                                    </Label>
                                </Col>

                            </Row>
                            <Row
                                style={{
                                    marginTop: "10px"
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <video
                                        style={{
                                            width: '90%',
                                            height: '100%',
                                            borderRadius: "8px"
                                        }}
                                        autoPlay
                                        playInline
                                        loop
                                        muted
                                        preload='auto'
                                    // controls
                                    >

                                        <source
                                            src={"https://static.videezy.com/system/resources/previews/000/050/643/original/cloud-network5.mp4"}
                                            type="video/mp4"
                                        />
                                        {/* <source
                                        src={"https://static.videezy.com/system/resources/previews/000/055/087/original/200812_01_HUD-Digital-screen-drive-blue-FUI-HD-Tilt.mp4"}
                                        type="video/mp4"
                                    />
                                     */}

                                    </video>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginTop: "5px",
                                    width: '90%',
                                    marginLeft: "5%"
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "justify",

                                    }}
                                >
                                    <Label
                                        style={{
                                            color: colors.blanco,
                                            fontSize: "14px",
                                            // fontWeight: "bold"
                                        }}
                                    >
                                        Sistema de seguridad capaz de detectar movimientos, temperatura y humedad del área asignada a vigilar, suministrar accesos controlados mediante la lectura de huellas dactilares y remotamente, registrar todos y cada uno de los datos y mostrarlos gráficamente en una aplicación web.
                                    </Label>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginTop: "80px",
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <Label
                                        style={{
                                            color: colors.blanco,
                                            fontSize: "24px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        Diciembre 2022
                                    </Label>
                                </Col>
                            </Row>

                        </Col>
                        <Col
                            style={{
                                borderRadius: "0px 8px 8px 0px",
                                width: "100%",
                                height: "100%",
                                backgroundImage: "radial-gradient(circle at 45.55% 24.77%, #ffffff 0, #ffffff 25%, #ffffff 50%, #f9f4f6 75%, #f3ebed 100%)"
                            }}
                        >
                            <Row
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "5%",
                                    marginRight: "5%",
                                    backgroundColor: "transparent",
                                    borderRadius: "8px",
                                    borderColor: "#000"
                                }}
                            >
                                <Col
                                    style={{
                                        // marginTop: "20px",
                                        textAlign: "center"
                                    }}
                                >
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            fontSize: "48px",
                                            color: "#A77BFF"
                                        }}
                                    >
                                        Inicio de Sesión
                                    </Label>`
                                </Col>`
                            </Row>
                            <Row
                                style={{
                                    marginTop: "30px"
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <img
                                        src={imgLogo}
                                        height="250px"
                                    />
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginTop: "10px",
                                    marginLeft: "5%",
                                    marginRight: "5%",
                                    width: "90%",
                                    borderRadius: "8px",
                                    borderColor: "#000",
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <AccountCircle
                                        style={{
                                            marginTop: "25px",
                                            color: colors.azul
                                        }}
                                    />
                                    <TextField
                                        label="Nombre de Usuario"
                                        // variant="outlined"
                                        id="IdIptUsername"
                                        startAdornme
                                        name="username"
                                        style={{
                                            width: "90%",
                                            marginLeft: "2%",
                                            color: "red",
                                        }}
                                        onChange={(e) => {

                                            this.handleChangeUsuario(e);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") this.fucusElement("IdIptPassword");
                                        }}
                                        value={this.state.usuario.username}
                                    // error
                                    />

                                </Col>

                            </Row>
                            <Row
                                style={{
                                    marginTop: "30px",
                                    marginLeft: "5%",
                                    marginRight: "5%",
                                    width: "90%",
                                    borderRadius: "8px",
                                    borderColor: "#000",
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <Lock
                                        style={{
                                            marginTop: "25px",
                                            color: colors.azul
                                        }}
                                    />
                                    <TextField
                                        label="Contraseña"
                                        // variant="outlined"
                                        id="IdIptPassword"
                                        startAdornme
                                        name="password"
                                        style={{
                                            width: "80%",
                                            marginLeft: "2%",
                                            color: "red",
                                        }}
                                        onChange={(e) => {
                                            this.handleChangeUsuario(e);
                                        }}
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter") {
                                                // Mandamos a llamar la funcion para loguearse

                                                this.iniciarSesion();

                                            }
                                        }}
                                        type={this.state.isVisibilityPassword ? "text" : "password"}
                                        value={this.state.usuario.password}
                                    // error
                                    />

                                    <IconButton
                                        style={{
                                            cursor: "pointer",
                                            opacity: this.state.opacity,
                                            marginTop: "10px"
                                        }}
                                        onClick={async () => {
                                            await this.setState({
                                                isVisibilityPassword: !this.state.isVisibilityPassword
                                            });
                                        }}>
                                        {this.state.isVisibilityPassword ?
                                            <Visibility style={{ color: colors.azul, }} />
                                            :
                                            <VisibilityOff style={{ color: colors.azul }} />
                                        }
                                    </IconButton>

                                </Col>
                            </Row>
                            <Row
                                style={{
                                    marginTop: "50px",
                                    marginLeft: "5%",
                                    marginRight: "5%",
                                    width: "90%",
                                    borderRadius: "8px",
                                    borderColor: "#000",
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            width: "100%",
                                            backgroundImage: "radial-gradient(circle at 4.07% 23.48%, #8164ff 0, #7a61ff 50%, #735efc 100%)",
                                            fontWeight: "bold",
                                        }}
                                        onClick={()=>{
                                            this.fucusElement("IdIptUsername");
                                            this.setState({
                                                usuario: {
                                                    username: "",
                                                    password: "",
                                                },
                                            });  
                                        }}
                                    >
                                        Cancelar
                                    </Button>
                                </Col>
                                <Col
                                    style={{
                                        textAlign: "right"
                                    }}>
                                    <Button
                                        style={{
                                            width: "100%",
                                            backgroundImage: "radial-gradient(circle at 4.07% 23.48%, #8164ff 0, #7a61ff 50%, #735efc 100%)",
                                            fontWeight: "bold"
                                        }}
                                        onClick={() => {
                                            this.iniciarSesion();
                                        }}
                                    >
                                        Iniciar Sesión
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    :
                    <>
                        <div>
                            <div
                                style={{
                                    height: (this.state.screenHeight) + "px",
                                    width: (this.state.screenWidth) + "px",
                                    backgroundImage: "radial-gradient(circle at 36.27% 101.23%, #c18bff 0, #a076ff 25%, #7a61ff 50%, #4b4def 75%, #003de0 100%)",


                                }}>
                                <Row
                                    style={{
                                        height: "20px"
                                    }}
                                >

                                </Row>
                                <Row
                                    style={{
                                        marginLeft: "10px",
                                        marginRight: "10px",
                                        backgroundColor: "#fff",
                                        // filter: "blur(4px)",
                                        borderRadius: "8px"
                                    }}
                                >
                                    <Row
                                        style={{
                                            marginTop: "20px"
                                        }}
                                    >
                                        <Col
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            <img
                                                src={img}
                                                height="150px"
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop:"10px"
                                        }}
                                    >
                                        <Col
                                            style={{
                                                textAlign:"center",
                                            }}
                                        >
                                            <Label
                                                style={{
                                                    color : this.state.message[this.state.indice].color,
                                                    fontWeight : "bold"
                                                }}
                                            >
                                                {this.state.message[this.state.indice].label}
                                            </Label>
                                        </Col>
                                    </Row>

                                    <Row
                                        style={{
                                            marginTop: "20px"
                                        }}
                                    >
                                        <Col
                                            style={{
                                                textAlign: 'center',

                                            }}
                                        >
                                            <TextField
                                                label="Nombre de Usuario"
                                                // variant="outlined"
                                                startAdornme
                                                id="IdIptUsername"
                                                name="username"
                                                style={{
                                                    width: "108%",
                                                    color: "red"
                                                }}
                                                onChange={(e) => {

                                                    this.handleChangeUsuario(e);
                                                }}
                                                value={this.state.usuario.username}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") this.fucusElement("IdIptPassword");
                                                }}
                                            // error
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col
                                            style={{
                                                textAlign: 'center',
                                                marginTop: "20px"
                                            }}
                                        >

                                            <TextField
                                                label="Contraseña"
                                                // variant="outlined"
                                                id="IdIptPassword"
                                                startAdornme
                                                name="password"
                                                style={{
                                                    width: "108%",
                                                }}
                                                onChange={(e) => {
                                                    this.handleChangeUsuario(e);
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        // Mandamos a llamar la funcion para loguearse

                                                        this.iniciarSesion();

                                                    }
                                                }}
                                                type={this.state.isVisibilityPassword ? "text" : "password"}
                                                value={this.state.usuario.password}
                                            />

                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: "20px"
                                        }}
                                    >
                                        <Col>
                                            <Label>Mostar contraseña</Label>
                                            <Input
                                                type="checkbox"
                                                style={{
                                                    marginLeft: "10px",
                                                    marginTop: "5px"
                                                }}
                                                onClick={async () => {
                                                    await this.setState({
                                                        isVisibilityPassword: !this.state.isVisibilityPassword
                                                    });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: "30px"
                                        }}
                                    >
                                        <Col
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    width: "108%",
                                                    // backgroundColor: colors.azul,
                                                    backgroundImage: "radial-gradient(circle at 4.07% 23.48%, #8164ff 0, #7a61ff 50%, #735efc 100%)",
                                                    color: colors.blanco,
                                                    fontWeight: "bold"
                                                }}
                                                onClick={() => {
                                                    this.iniciarSesion();
                                                }}
                                            >
                                                Iniciar Sesión
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: "20px"
                                        }}
                                    >
                                        <Col
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    width: "108%",
                                                    backgroundColor: colors.gris,
                                                    color: colors.blanco,
                                                    fontWeight: "bold"
                                                }}
                                                onClick={async ()=>{
                                                    await this.setState({
                                                        indice : 0,
                                                        usuario: {
                                                            username: "",
                                                            password: "",
                                                        },
                                                    });                                                   
                                                  this.fucusElement("IdIptUsername");  
                                                }}
                                            >
                                                Cancelar
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: "20px"
                                        }}
                                    >
                                        <Col
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >

                                        </Col>
                                    </Row>
                                </Row>

                            </div>
                        </div>

                    </>

                }
            </>
        );
    }

}



export default Login;