const isProducction = true;
var API = "";

if (isProducction) {

    // API = "https://lddevsoft.com/api/checkroom";
    // API = "https://checkoffice.dominosgpm.com.mx/api/checkroom";
    API = "https://checkofficeenterprise.dominosgpm.com.mx/api/checkoffice"


} else { 

    const IP = "127.0.0.1";
    // const IP = "192.168.0.9";
    // const IP = "192.168.3.189";
    // const IP = "192.168.1.96";

    const PORT = "3001";

    API = `http://${IP}:${PORT}/api/checkoffice`;
    
}

export default API;