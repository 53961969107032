import swal2 from 'sweetalert2';

export const Toast = swal2.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', swal2.stopTimer)
        toast.addEventListener('mouseleave', swal2.resumeTimer)
    }
});