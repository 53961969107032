import { Component } from "react";
import {
    Row,
    Col,
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupText,
    Button,
    FormFeedback,
} from "reactstrap";

import swal2 from 'sweetalert2';
import {
    AccountCircle,
    Lock,
    VisibilityOff,
    Visibility,
} from '@material-ui/icons';
import userLogo from "../multimedia/logoLogin.svg";
import colors from "../configuration/colors.config"
import Cookies from "universal-cookie";


import userModel from "../models/user.model";
import { makeStyles } from '@material-ui/core/styles';
const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
}));

class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMobileDevice: false,
            inputType: "password",
            usuario: {
                username: "",
                password: "",
                IdUsuarioTipo: 2
            },
            style: {
                label: {
                    fontSize: "20px",
                    color: colors.blanco
                },
                input: {
                    boxShadow: "none",
                    borderColor: colors.azul,
                },
                row: {
                    marginRight: "10px",
                    marginLeft: "10px",
                },
                button: {
                    width: "100%",
                    backgroundColor: colors.rosado,
                    borderColor: colors.rosado,
                    borderRadius: "8px",
                    color: colors.blanco,
                    fontSize: "19px",
                }
            },
            input: {
                username: false,
                password: false,
            },
            usuarioStatus: 0,
        }
    }


    async componentDidMount() {

        document.body.style.backgroundColor = colors.azul_oscuro;
        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        this.setState({
            isMobileDevice: isMobileDevice,
        });



    }

    handleChangeUsuario = async (e) => {

        let { name, value } = e.target;
        // console.log(name, value);
        await this.setState({
            usuario: {
                ...this.state.usuario,
                [name]: value,
            },
            input: {
                ...this.state.input,
                [name]: value.length === 0 ? true : false,
            }
        });

    }

    fucusElement = (Id) => {
        document.getElementById(Id).focus();
    }

    Toast = swal2.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 60000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', swal2.stopTimer)
            toast.addEventListener('mouseleave', swal2.resumeTimer)
        }
    });

    iniciarSesion = async () => {


        this.Toast.fire({
            icon: 'success',
            text: "Logueando Usuario",
            timer: 50000
        });

        // setTimeout(()=>
        // window.location.href = '/main'
        // ,2000);



        if (this.state.usuario.username.length === 0 || this.state.usuario.password.length === 0) {

            this.Toast.fire({
                icon: 'warning',
                text: "Faltan datos para poder loguearte"
            });

            this.setState({
                input: {
                    username: this.state.usuario.username.length === 0 ? true : false,
                    password: this.state.usuario.password.length === 0 ? true : false,
                }
            });

            if (this.state.usuario.username.length === 0) {
                this.fucusElement("IdIptUsername");
            } else {
                this.fucusElement("IdIptPassword");
            }

        } else {
            this.Toast.fire({
                icon: 'info',
                text: "Iniciando Sesion"
            });

            await userModel.login(this.state.usuario, async (data) => {

                // console.log(data);

                let { status, user } = data;


                if (status === 200) {
                    cookies.set("token", user.token, { path: "/" });
                    cookies.set("user", user, { path: "/" });

                    window.location.href = "/main"


                } else {
                    swal2.fire({
                        icon: "warning",
                        title: "Acceso Denegado",
                        text: "Nombre de usuario y/o contraseña incorrectos",
                        timer: "60000",
                        confirmButtonColor: colors.rosado,
                        confirmButtonText: 'Okey',
                        allowEscapeKey: true,
                        timerProgressBar: true,

                    });
                }

            });
        }

    }

    render() {
        return (
            <>
                <div

                >
                    <div
                        style={{
                            backgroundColor: colors.azul,
                            marginTop: this.state.isMobileDevice ? "10%" : "3%",
                            marginLeft: this.state.isMobileDevice ? "10px" : "32.5%",
                            marginRight: this.state.isMobileDevice ? "10px" : "32.5%",
                            width: this.state.isMobileDevice ? "" : "35%",
                            borderRadius: "8px"
                        }}
                    >
                        <Row style={this.state.style.row}>
                            <Col
                                style={{
                                    marginTop: "40px",
                                    textAlign: "center"
                                }}
                            >
                                <Row>
                                    <Col>
                                        <img
                                            src={userLogo}
                                            height="150px"
                                            alt="userLogo"
                                        />
                                    </Col>
                                </Row>

                                {/* <br /> */}
                                <Row>
                                    <Col
                                        style={{
                                            marginTop: "20px",

                                        }}
                                    >
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "35px",
                                                color: colors.blanco
                                            }}
                                        >
                                            Inicio de Sesión
                                        </Label>`
                                    </Col>`
                                </Row>
                                {
                                    this.state.usuarioStatus === 404 ?
                                        <Label
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "24px",
                                                color: "red"
                                            }}
                                        >
                                            Acceso Nedegado
                                        </Label>
                                        :
                                        this.state.usuarioStatus === 201 ?
                                            <Label
                                                style={{
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                    color: "orange"
                                                }}
                                            >
                                                Cuenta Bloqueada
                                            </Label>
                                            :
                                            null
                                }

                                <Row>
                                    <Col>

                                    </Col>
                                </Row>

                            </Col>
                        </Row>

                        <Row
                            style={this.state.style.row}
                        >
                            <Col
                                style={{
                                    marginTop: "30px"
                                }}
                            >
                                <FormGroup>
                                    <Label
                                        style={this.state.style.label}
                                    >
                                        Nombre de Usuario
                                    </Label>
                                    <InputGroup>

                                        <InputGroupText
                                            style={{
                                                borderColor: colors.azul,
                                                backgroundColor: colors.azul,
                                            }}
                                        >
                                            <AccountCircle style={{ color: colors.azul_oscuro, backgroundColor: colors.azul }} />
                                        </InputGroupText>
                                        <Input
                                            id="IdIptUsername"
                                            autoFocus
                                            style={this.state.style.input}
                                            name="username"
                                            onChange={(e) => {
                                                this.handleChangeUsuario(e);
                                            }}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") this.fucusElement("IdIptPassword");
                                            }}
                                            value={this.state.usuario.username}
                                            type="text"
                                            invalid={this.state.input.username}
                                        />
                                        <FormFeedback
                                            tooltip
                                        // style={this.state.formFeedbackStyle}
                                        >
                                            Falta el Nombre de Usuario.
                                        </FormFeedback>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row
                            style={this.state.style.row}
                        >
                            <Col
                                style={{
                                }}
                            >
                                <FormGroup>
                                    <Label
                                        style={this.state.style.label}
                                    >
                                        Contraseña
                                    </Label>
                                    <InputGroup>
                                        <InputGroupText
                                            style={{
                                                borderColor: colors.azul,
                                                backgroundColor: colors.azul,
                                            }}
                                        >
                                            <Lock style={{ color: colors.azul_oscuro, backgroundColor: colors.azul }} />
                                        </InputGroupText>
                                        <Input
                                            id="IdIptPassword"
                                            style={this.state.style.input}
                                            type={this.state.inputType}
                                            name="password"
                                            onChange={(e) => {
                                                this.handleChangeUsuario(e);
                                            }}
                                            value={this.state.usuario.password}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    // Mandamos a llamar la funcion para loguearse

                                                    this.iniciarSesion();

                                                }
                                            }}
                                            invalid={this.state.input.password}
                                        />
                                        <FormFeedback
                                            tooltip
                                        // style={this.state.formFeedbackStyle}
                                        >
                                            Falta el contraseña del Usuario.
                                        </FormFeedback>
                                        <InputGroupText
                                            style={{
                                                cursor: "pointer",
                                                backgroundColor: colors.blaco,
                                                opacity: this.state.opacity,
                                            }}
                                            onClick={async () => {
                                                await this.setState({
                                                    isVisibilityPassword: !this.state.isVisibilityPassword,
                                                    inputType: this.state.isVisibilityPassword ? "password" : "text",
                                                });
                                            }}>
                                            {this.state.isVisibilityPassword ?
                                                <Visibility style={{ color: colors.azul }} />
                                                :
                                                <VisibilityOff style={{ color: colors.azul }} />
                                            }
                                        </InputGroupText>
                                    </InputGroup>
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                marginTop: "30px",
                                marginBottom: "30px"
                            }}
                        >
                            <Col

                            >
                                <Button
                                    style={this.state.style.button}
                                    onClick={async () => {
                                        await this.setState({
                                            input: {
                                                username: false,
                                                password: false,
                                            },
                                            usuario: {
                                                username: "",
                                                password: "",
                                            },
                                            usuarioStatus: 0,
                                        });
                                        this.fucusElement("IdIptUsername");

                                    }}
                                >
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button
                                    style={this.state.style.button}
                                    onClick={() => {
                                        this.iniciarSesion();

                                    }}
                                >
                                    {
                                        this.state.isMobileDevice ?
                                            "Iniciar"
                                            :
                                            "Iniciar Sesión"
                                    }

                                    {/* Iniciar sesión */}
                                </Button>
                            </Col>
                        </Row>

                        <Row
                            style={{
                                marginRight: "10px",
                                marginLeft: "10px",
                                marginTop: "30px",
                                marginBottom: "30px"
                            }}>
                            {/* <Col>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        color: colors.blanco,
                                        fontSize: "18px"
                                    }}
                                >
                                    ¿No tienes una cuenta?
                                    <a
                                        href="/"
                                        style={{
                                            marginLeft: "7px",
                                            //fontWeight: "normal",
                                            color: colors.blanco,
                                        }}
                                    >crea una cuenta aqui</a>
                                </Label>
                            </Col> */}
                        </Row>

                        <Row><Col></Col></Row>


                    </div>
                </div>
            </>
        );
    }

}

export default Login;