import { Component } from "react";
import NavBar from "../components/NavBar";
import colors from "../configuration/colors.config"
import Table from "react-data-table-component";
// import IconButton from "@material-ui/core/IconButton";
import swal2 from 'sweetalert2';
import IconButton from "@material-ui/core/IconButton";
import Slider from '@material-ui/core/Slider';
import userModel from "../models/user.model";
import {
    Button,
    Row,
    Col,
    Label,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Spinner,
    DropdownItem,
} from "reactstrap";
import TextField from '@material-ui/core/TextField';
import gifLoading from "../multimedia/gif/loading.gif";
import {
    Cached,
    Check,
    Clear,
    Visibility,
    ToggleOn,
    ToggleOff,
    RotateLeft,
    MeetingRoom,
    Fingerprint,
    SettingsRemote,
    Group,
} from '@material-ui/icons';
import date from "../models/date.model";
import time from "../models/time.model";
import { Toast } from "../models/Toast.model";
import chapaModel from "../models/chapa.model";
import dispositivoModel from "../models/dispositivo.model";
import firebase from 'firebase';
import Swal from "sweetalert2";
var database = firebase.database();
let primero = true;

let tipoDispositivo = 0;

class TableDispositivos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ultimaLecturaActiva: false,
            refTable: 1,
            openModalHuellasEnroladas: false,
            haySusuarioSeleccionado: false,
            usuarioSeleccionado: {
                isSelect: false,
                idusuarios: "",
                nombreCompleto: "",
                nombreUsuario: "",
            },
            dataListUsuarios: [],
            noDataComponent: "No hay chapas y sensores guardados",
            statusModoVigilante: {
                text: {
                    0: "Desactivado",
                    1: "Activado"
                },
                colors: {
                    0: "grey",
                    1: colors.verdeSuccess
                },
                descripcion: {
                    0: "No se mandaran notificaciones",
                    1: "Se mandaran notificaciones"
                }
            },
            iconDispisitio: {

            },
            colorSatus: {
                0: colors.rojo,
                1: colors.verdeSuccess,
                2: "",
                3: colors.verdeSuccess,
                4: "",
                5: "grey",
                6: "",
                7: "grey",
                8: "grey",
                9: colors.verdeSuccess,
                10: "grey",
                11: colors.azul_oscuro,
                12: colors.azul_oscuro,
                13: colors.azul_oscuro,
                14: colors.azul_oscuro,
                15: colors.azul_oscuro,
                16: colors.verdeSuccess,
                17: colors.naranjaWarning,
            },
            dataColumdUsuario: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Id Usuario</p>,
                    selector: row => row.idusuarios,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombre completo</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.nombre + " " + row.paterno + " " + row.materno} >{row.nombre + " " + row.paterno + " " + row.materno}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombre de usuario</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.username} >{row.username}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Acciones</p>,
                    selector: row =>
                        <>
                            <IconButton
                                onClick={async () => {
                                    if (this.state.refTable == 1) {
                                        await this.setState({
                                            usuarioSeleccionado: {
                                                isSelect: true,
                                                idusuarios: row.idusuarios,
                                                nombreCompleto: row.nombre + " " + row.paterno + " " + row.materno,
                                                nombreUsuario: row.username,
                                            }
                                        });
                                    } else {

                                        swal2.fire({
                                            icon: 'question',
                                            title: `Negar Acceso`,
                                            text: `¿Estas seguro de que quieres negarle el acceso al usuario ${row.nombre + " " + row.paterno + " " + row.materno} "${row.username}"?`,
                                            timer: "20000",
                                            confirmButtonColor: '#016390',
                                            confirmButtonText: 'Si',
                                            cancelButtonText: "No",
                                            allowEscapeKey: true,
                                            showCancelButton: true,
                                            reverseButtons: true,
                                            timerProgressBar: true,
                                            //footer : '<p style="color:'+colors.azul_oscuro+';"  >No se recuoeraran los datos anteriores</p>',
                                        }).then(async (response) => {
                                            if (response.isConfirmed) {
                                                let data = {
                                                    IdChapa: this.state.dispositivo.Id,
                                                    IdUsuario: row.idusuarios
                                                }
                                                // console.log(data);
                                                let result = await userModel.usuario_quitar_acceso_chapa(data);

                                                if (result.status == 200) {
                                                    Toast.fire({
                                                        icon: "success",
                                                        title: "Acceso negado",

                                                    });
                                                    result = await userModel.usuario_enrolados_chapa(this.state.dispositivo.Id);
                                                    // console.log(result.listUsuarios);
                                                    let dataListUsuarios = result.listUsuarios;
                                                    await this.setState({
                                                        // openModalHuellasEnroladas: true,
                                                        dataListUsuarios,
                                                        // refTable: 2,
                                                    });

                                                }
                                            }
                                        });


                                    }
                                }}
                            >
                                {
                                    this.state.refTable == 1 ?
                                        <Check
                                            style={{
                                                color: colors.verdeSuccess,
                                            }}
                                        />
                                        :
                                        <Clear
                                            style={{
                                                color: colors.rojo,
                                            }}
                                        />

                                }

                            </IconButton>
                        </>
                    ,
                    sortable: true
                },
            ],
            dataColumd: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Codigo</p>,
                    selector: row => row.codigo,
                    sortable: true,
                    // width: "150px",
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Tipo</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.descripcionTipo} >{row.tipo}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Ubicacion</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.descripcionUbicacion} >{row.ubicacion}</abbr>,
                    sortable: true,
                    // width: "200px",

                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Descripcion ubicacion</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.descripcionUbicacion} >{row.descripcionUbicacion}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Modo Vigilante</p>,
                    selector: row =>
                        <abbr
                            style={{ textDecoration: "none" }}
                            title={this.state.statusModoVigilante.descripcion[row.modoVigilante]}
                        >
                            <label
                                style={{
                                    fontWeight: "bold",
                                    color: this.state.statusModoVigilante.colors[row.modoVigilante]
                                }}
                            >
                                {this.state.statusModoVigilante.text[row.modoVigilante]}
                            </label>
                        </abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Acciones</p>,
                    selector: row =>
                        <IconButton
                            onClick={async () => {

                                if (row.IdTipo == 1) {
                                    let cahpa = await {
                                        origen: 1,
                                        IdChapa: row.IdTipo == 1 ? row.IdChapa : row.IdDispositivo,
                                        IdStatus: 5,
                                    }
                                    await chapaModel.chapa_cambiar_status(cahpa);
                                } else {
                                    let dispositivo = await {
                                        origen: 1,
                                        IdDispositivo: row.IdTipo == 1 ? row.IdChapa : row.IdDispositivo,
                                        IdStatus: 5,
                                    }
                                    await dispositivoModel.dispositvo_cambiar_status(dispositivo);
                                }
                                await this.setState({

                                    dispositivo: {
                                        Id: row.IdTipo == 1 ? row.IdChapa : row.IdDispositivo,
                                        codigo: row.codigo,
                                        tipo: row.tipo,
                                        IdTipo: row.IdTipo,
                                        ubicacion: row.ubicacion,
                                        descripcionUbicacion: row.descripcionUbicacion,
                                        modoVigilante: row.modoVigilante,
                                        statusDispositivo: row.IdStatus,
                                        status: "",
                                        minTemperatura: row.minTemperatura,
                                        maxTemperatura: row.maxTemperatura,
                                        minHumedad: row.minHumedad,
                                        maxHumedad: row.maxHumedad,
                                        guardarRegistro : row.guardarRegistro,
                                    },
                                    openModal: true,
                                });

                                this.sleep(20).then(async () => {
                                    if (this.state.dispositivo.statusDispositivo == 5) {
                                        // this.setState({
                                        //     dispositivo: {
                                        //         ...this.state.dispositivo,
                                        //         status: row.IdTipo == 1 ? "No se pudo conectar con la chapa" : "No se pudo conectar con el dispositivo",
                                        //         statusDispositivo: 0,
                                        //     },
                                        // });
                                        if (row.IdTipo == 1) {
                                            let cahpa = await {
                                                origen: 1,
                                                IdChapa: row.IdTipo == 1 ? row.IdChapa : row.IdDispositivo,
                                                IdStatus: 0,
                                            }
                                            await chapaModel.chapa_cambiar_status(cahpa);
                                        } else {
                                            let dispositivo = await {
                                                origen: 1,
                                                IdDispositivo: row.IdTipo == 1 ? row.IdChapa : row.IdDispositivo,
                                                IdStatus: 0,
                                            }
                                            await dispositivoModel.dispositvo_cambiar_status(dispositivo);
                                        }
                                    }

                                });
                            }}
                        >
                            <Visibility
                                style={{
                                    color: colors.azul,
                                    // backgroundColor : colors.blanco
                                }}
                            />
                        </IconButton>,
                    sortable: true
                },
            ],
            dispositivo: {
                Id: 0,
                codigo: "",
                tipo: "",
                IdTipo: 0,
                ubicacion: "",
                descripcionUbicacion: "",
                modoVigilante: 0,
                statusDispositivo: 0,
                minTemperatura: 0,
                maxTemperatura: 0,
                minHumedad: 0,
                maxHumedad: 0,
                guardarRegistro : 0,
            },
            dataList: [],
            isMobileDevice: false,
            openModal: false,
            openModalListUsuarios: false,
            isLoasTable: true,
            isLoasTableUsuario: true,
            ultimaLectura: {
                humedad: 0,
                temperatura: 0,
                fecha: "",
                hora: "",
            }
        }
    }

    sleep(seconds) {
        return new Promise(resolve => setTimeout(resolve, (seconds * 1000)));
    }

    componentDidMount() {

        document.body.style.backgroundColor = colors.azul;

        document.body.style.backgroundColor = colors.azul;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        this.setState({
            isMobileDevice: isMobileDevice,
        });

        this.cargarDataPge();

    }

    listarUsuarios = async () => {
        let data = await userModel.usuarios_listar();
        if (data == undefined) {
            this.setState({
                isLoasTableUsuario: false
            });
        } else {
            let dataListUsuarios = data.users;
            this.setState({
                dataListUsuarios,
                isLoasTableUsuario: false
            });
            // Toast.fire({
            //     icon: "success",
            //     title: "Tabla cargada"
            // });
        }
    }

    cargarDataPge = async () => {

        database.ref(`dispositivos/`).on('value', async (snapshot) => {
            if (snapshot.exists() && this.state.dispositivo.Id > 0) {
                if (this.state.dispositivo.IdTipo == 2) {
                    let data = await dispositivoModel.dispositivo_porId(this.state.dispositivo.Id);
                    let { dispositivo } = data;
                    await this.setState({
                        dispositivo: {
                            ...this.state.dispositivo,
                            status: dispositivo.status,
                            statusDispositivo: dispositivo.IdStatus,
                        },

                    });

                    if (dispositivo.IdStatus == 9) {
                        let data = await dispositivoModel.dispositivo_obtener_ultima_lectura(this.state.dispositivo.Id);
                        let { ultimaLectura } = data;

                        console.log(ultimaLectura);

                        this.setState({
                            ultimaLecturaActiva: true,
                            ultimaLectura,
                        });
                    }


                }



            }

        });

        database.ref(`chapas/`).on('value', async (snapshot) => {
            if (snapshot.exists() && this.state.dispositivo.Id > 0) {
                if (this.state.dispositivo.IdTipo == 1) {
                    let data = await chapaModel.chapa_porId(this.state.dispositivo.Id);
                    let { dispositivo } = data;
                    await this.setState({
                        dispositivo: {
                            ...this.state.dispositivo,
                            status: dispositivo.status,
                            statusDispositivo: dispositivo.IdStatus
                        },
                    });
                }



            }
        });

        database.ref(`tablaDispositivosChapa/`).on('value', async (snapshot) => {
            if (snapshot.exists()) {
                if (tipoDispositivo == 0) {
                    this.listarDispositivosChapas();
                }
                if (tipoDispositivo == 1) {
                    this.listarChapas();
                }
                if (tipoDispositivo == 2) {
                    this.listarDispositivos();

                }

            }
        });



        await this.listarDispositivosChapas();
    }

    listarDispositivosChapas = async () => {
        let data = await dispositivoModel.dispositivos_chapa_list();

        console.log(data);
        if (data == undefined) {
            this.setState({
                isLoasTable: false
            });
        } else {
            let { dataList } = data;
            this.setState({
                dataList,
                isLoasTable: false
            });
        }

    }

    listarDispositivos = async () => {
        let data = await dispositivoModel.dispositivos_list();

        if (data == undefined) {
            this.setState({
                isLoasTable: false
            });
        } else {
            let { dataList } = data;
            this.setState({
                dataList,
                noDataComponent: dataList.length == 0 ? "No hay dispositivos guardados" : "",
                isLoasTable: false
            });
        }

    }

    listarChapas = async () => {
        let data = await chapaModel.chapa_list();
        if (data == undefined) {
            this.setState({
                isLoasTable: false
            });
        } else {
            let { dataList } = data;

            this.setState({
                dataList,
                noDataComponent: dataList.length == 0 ? "No hay chapas guardados" : "",
                isLoasTable: false
            });
        }
    }


    guardarDispositivoChapa = () => {
        swal2.fire({
            icon: 'question',
            title: `Guardar ${this.state.dispositivo.IdTipo == 2 ? "Dispositivo" : "Chapa"}`,
            text: `¿Estas seguro de que quieres actualizar ${this.state.dispositivo.IdTipo == 2 ? " este dispositivo" : " esta chapa"} con estos datos?`,
            timer: "10000",
            confirmButtonColor: '#016390',
            confirmButtonText: 'Si, guardar',
            cancelButtonText: "No guardar",
            allowEscapeKey: true,
            showCancelButton: true,
            reverseButtons: true,
            timerProgressBar: true,
            //footer : '<p style="color:'+colors.azul_oscuro+';"  >No se recuoeraran los datos anteriores</p>',
        }).then(async (response) => {
            if (response.isConfirmed) {
                if (this.state.dispositivo.IdTipo == 2) {
                    let data = await dispositivoModel.dispositivo_guardar(this.state.dispositivo);
                    if (data.status == 200) {
                        Toast.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Datos del dispositivo guardado"
                        });
                    } else {
                        Toast.fire({
                            position: "top-end",
                            icon: "warning",
                            title: "No se pudieron guardar los datos del dispositivo"
                        });
                    }
                } else {
                    let data = await chapaModel.chapa_guardar(this.state.dispositivo);
                    if (data.status == 200) {
                        Toast.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Datos de la chapa guardada"
                        });
                    } else {
                        Toast.fire({
                            position: "top-end",
                            icon: "warning",
                            title: "No se pudieron guardar los datos de la chapa"
                        });
                    }
                }
                this.setState({
                    isLoasTable: true
                });
                if (tipoDispositivo == 0) {

                    this.listarDispositivosChapas();
                }
                if (tipoDispositivo == 1) {
                    this.listarChapas();
                }
                if (tipoDispositivo == 2) {
                    this.listarDispositivos();

                }

            }
        });

    }

    handleChangeDispositivo = (e) => {
        let { name, value } = e.target;

        this.setState({
            dispositivo: {
                ...this.state.dispositivo,
                [name]: value
            }
        });

    }

    handleChangeTipoDispositivo = (e) => {
        this.setState({
            isLoasTable: true
        });
        let { value } = e.target;
        tipoDispositivo = value;
        if (value == 0) {
            this.listarDispositivosChapas();
        }
        if (value == 1) {
            this.listarChapas();
        }
        if (value == 2) {
            this.listarDispositivos();
        }
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} />
                <Row
                    style={{
                        marginTop: this.state.isMobileDevice ? "15px" : "100px",
                        marginLeft: this.state.isMobileDevice ? "5px" : "2%",
                        marginRight: this.state.isMobileDevice ? "5px" : "2%",
                        backgroundColor: colors.blanco,
                        height: "60px",
                        borderRadius: "8px",
                    }}
                >

                    <Col>
                        {
                            this.state.isLoasTable ?
                                <Label
                                    style={{
                                        marginTop: "15px",
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                        color: colors.azul_oscuro
                                    }}
                                >
                                    {!this.state.isMobileDevice ?
                                        " Cargando datos, espere por favor..."
                                        :
                                        "Cargando..."
                                    }

                                </Label>

                                :
                                null
                        }

                    </Col>
                    <Col
                        style={{
                            textAlign: "right",
                        }}
                    >

                        <Button

                            onClick={async () => {
                                this.setState({
                                    isLoasTable: true
                                });
                                if (tipoDispositivo == 0) {

                                    this.listarDispositivosChapas();
                                }
                                if (tipoDispositivo == 1) {
                                    this.listarChapas();
                                }
                                if (tipoDispositivo == 2) {
                                    this.listarDispositivos();

                                }


                            }}
                            style={{
                                color: colors.blanco,
                                marginTop: "10px",
                                marginRight: "10px",
                                backgroundColor: colors.azul_oscuro,
                                borderColor: colors.azul_oscuro,
                            }}
                        >
                            {
                                this.state.isLoasTable ?
                                    <Spinner
                                        style={{
                                            marginTop: "",
                                            height: '1.4rem',
                                            width: '1.4rem'
                                            // fontSize:"25px"
                                        }}
                                    />
                                    :
                                    <Cached />
                            }

                        </Button>
                    </Col>

                </Row>

                <div
                    style={{
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginTop: this.state.isMobileDevice ? "20px" : "10px",
                        height: "90%",
                    }}
                >
                    <Row
                        style={{
                            marginLeft: "1%",

                        }}
                    >
                        <Col
                            style={{

                            }}
                        >
                            <Label
                                style={{
                                    marginTop: "10px",
                                    // fontWeight:"bold",
                                    fontSize: "18px"
                                }}
                            >
                                Tipo dispositivo
                            </Label>
                            <Input
                                style={{
                                    width: this.state.isMobileDevice ? "95%" : "250px"
                                }}
                                onChange={(e) => {
                                    this.handleChangeTipoDispositivo(e)
                                }}
                                name="tipoDispositivo"
                                type="select"
                                disabled={this.state.isLoasTable}
                            >
                                <option value={0}>-Todos-</option>
                                <option value={2}>Sensor THM</option>
                                <option value={1}>Chapas electricas</option>
                            </Input>
                        </Col>
                    </Row>
                    <div
                        style={{
                            borderRadius: "8px",
                            marginLeft: "10px",
                            marginRight: "10px",
                            marginTop: "5px"
                        }}
                    >
                        {this.state.isLoasTable ?
                            <Row
                                style={{
                                    marginLeft: this.state.isMobileDevice ? "0px" : "0px",
                                    marginRight: this.state.isMobileDevice ? "0px" : "0px",
                                    backgroundColor: colors.blanco,
                                    // height: "80px",
                                    borderRadius: "0px 0px 8px 8px",
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "center"
                                    }}
                                >
                                    <img
                                        style={{
                                            marginTop: "30px",
                                            marginBottom: "50px"
                                        }}
                                        src={gifLoading}
                                        height={this.state.isMobileDevice ? 100 : 400}
                                    />
                                </Col>
                            </Row>
                            :
                            <Table
                                columns={this.state.dataColumd}
                                data={this.state.dataList}
                                title={<Label stle={{ fontSize: "24px", color: colors.negro }}>Tabla de Chapas Electricas</Label>}
                                noDataComponent={<p style={{ fontWeight: "bold" }}>{this.state.noDataComponent}</p>}
                                pagination
                                dense
                            />
                        }

                    </div>
                </div>
                <Row><Col></Col></Row>
                <Modal
                    isOpen={this.state.openModal}
                    size="lg"
                >
                    <ModalHeader
                        style={{
                            backgroundColor: colors.azul_oscuro,
                            color: colors.blanco
                        }}
                    >
                        Datos {this.state.dispositivo.IdTipo == 2 ? "del dispositivo" : "de la chapa"}
                    </ModalHeader>
                    <ModalBody
                        style={{
                            // backgroundColor: colors.azul,
                            // color: colors.blanco
                        }}
                    >
                        <Row
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label>CODIGO : {this.state.dispositivo.codigo} </Label>
                            </Col>
                            <Col
                                style={{
                                    textAlign: "center"
                                }}
                            >
                                <Label>TIPO : {this.state.dispositivo.tipo} </Label>
                            </Col>
                        </Row>
                        <DropdownItem divider />
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label>
                                        Estatus del dispositivo:
                                    </Label>
                                </Col>
                                <Col
                                    style={{
                                        textAlign: "right"
                                    }}
                                >
                                    {
                                        (this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) ?
                                            <Spinner
                                                size="sm"
                                                type="grow"
                                                style={{
                                                    color: colors.azul,
                                                    marginRight: "25px"
                                                }}
                                            />
                                            :
                                            <button
                                                style={{
                                                    height: "5px",
                                                    color: colors.azul,
                                                    marginRight: "25px",
                                                    borderColor: "transparent",
                                                    backgroundColor: "transparent"
                                                }}
                                                onClick={async () => {

                                                    let dispositivo = {
                                                        origen: 1,
                                                        IdDispositivo: this.state.dispositivo.Id,
                                                        IdChapa: this.state.dispositivo.Id,
                                                        IdStatus: 5,
                                                    }
                                                    if (this.state.dispositivo.IdTipo == 2) {
                                                        dispositivoModel.dispositvo_cambiar_status(dispositivo);
                                                        await this.sleep(20).then(async () => {
                                                            if (this.state.dispositivo.statusDispositivo == 5) {
                                                                // this.setState({
                                                                //     dispositivo: {
                                                                //         ...this.state.dispositivo,
                                                                //         status: this.state.dispositivo.IdTipo == 1 ? "No se pudo conectar con la chapa" : "No se pudo conectar con el dispositivo",
                                                                //         statusDispositivo: 0,
                                                                //     },
                                                                // });
                                                                if (this.state.dispositivo.IdTipo == 1) {
                                                                    let cahpa = await {
                                                                        origen: 1,
                                                                        IdDispositivo: this.state.dispositivo.Id,
                                                                        IdChapa: this.state.dispositivo.Id,
                                                                        IdStatus: 0,
                                                                    }
                                                                    await chapaModel.chapa_cambiar_status(cahpa);
                                                                } else {
                                                                    let dispositivo = await {
                                                                        origen: 1,
                                                                        IdDispositivo: this.state.dispositivo.Id,
                                                                        IdChapa: this.state.dispositivo.Id,
                                                                        IdStatus: 0,
                                                                    }
                                                                    await dispositivoModel.dispositvo_cambiar_status(dispositivo);
                                                                }
                                                            }
                                                        });
                                                    } else {
                                                        chapaModel.chapa_cambiar_status(dispositivo);
                                                        await this.sleep(20).then(async () => {
                                                            if (this.state.dispositivo.statusDispositivo == 5) {
                                                                // this.setState({
                                                                //     dispositivo: {
                                                                //         ...this.state.dispositivo,
                                                                //         status: this.state.dispositivo.IdTipo == 1 ? "No se pudo conectar con la chapa" : "No se pudo conectar con el dispositivo",
                                                                //         statusDispositivo: 0,
                                                                //     },
                                                                // });
                                                                if (this.state.dispositivo.IdTipo == 1) {
                                                                    let cahpa = await {
                                                                        origen: 1,
                                                                        IdDispositivo: this.state.dispositivo.Id,
                                                                        IdChapa: this.state.dispositivo.Id,
                                                                        IdStatus: 0,
                                                                    }
                                                                    await chapaModel.chapa_cambiar_status(cahpa);
                                                                } else {
                                                                    let dispositivo = await {
                                                                        origen: 1,
                                                                        IdDispositivo: this.state.dispositivo.Id,
                                                                        IdChapa: this.state.dispositivo.Id,
                                                                        IdStatus: 0,
                                                                    }
                                                                    await dispositivoModel.dispositvo_cambiar_status(dispositivo);
                                                                }
                                                            }
                                                        });
                                                    }

                                                }}
                                            >
                                                <Cached />
                                            </button>

                                    }
                                </Col>
                            </Row>

                            {/* <br /> */}
                            <Label
                                style={{
                                    color: this.state.colorSatus[this.state.dispositivo.statusDispositivo],
                                    fontWeight: "bold"
                                }}
                            >
                                {this.state.dispositivo.status}
                            </Label>
                            {
                                this.state.dispositivo.statusDispositivo == 0 ?
                                    <Label
                                        style={{
                                            fontWeight: "bold",
                                            color: colors.rojo,
                                        }}
                                    >
                                        {this.state.dispositivo.IdTipo == 1 ? "La chapa" : " El dispositivo"} esta {this.state.dispositivo.IdTipo == 1 ? "apagada" : "apagado"} o no esta {this.state.dispositivo.IdTipo == 1 ? "conectada" : "conectado"} a internet, verificar lo antes posible.
                                    </Label>
                                    :
                                    null
                            }
                        </FormGroup>

                        {
                            this.state.haySusuarioSeleccionado ?
                                this.state.dispositivo.statusDispositivo == 16 ?
                                    <Row>
                                        <Label>El usuario: {this.state.usuarioSeleccionado.nombreCompleto} "{this.state.usuarioSeleccionado.nombreUsuario}" a sido enrrolado</Label>
                                    </Row>
                                    :
                                    <Row>
                                        <Label>Enrrolando al usuario: {this.state.usuarioSeleccionado.nombreCompleto} "{this.state.usuarioSeleccionado.nombreUsuario}"</Label>
                                    </Row>
                                :
                                null
                        }

                        <DropdownItem divider />

                        <FormGroup>
                            <Label>
                                Ubicacion
                            </Label>
                            <Input
                                name="ubicacion"
                                type="text"
                                onChange={(e) => {
                                    this.handleChangeDispositivo(e);
                                }}
                                value={this.state.dispositivo.ubicacion}
                            />
                        </FormGroup>


                        <FormGroup>
                            <Label>
                                Descripcion de la ubicacion
                            </Label>
                            <Input
                                name="descripcionUbicacion"
                                type="text"
                                onChange={(e) => {
                                    this.handleChangeDispositivo(e);
                                }}
                                value={this.state.dispositivo.descripcionUbicacion}
                            />
                        </FormGroup>

                        <Row>
                            <Col
                                md="6"
                            >
                                <FormGroup>
                                    <Label>
                                        Mandar Notificaciones
                                    </Label>
                                    <button

                                        onClick={async () => {
                                            await this.setState({
                                                dispositivo: {
                                                    ...this.state.dispositivo,
                                                    modoVigilante: this.state.dispositivo.modoVigilante === 1 ? 0 : 1
                                                }
                                            });

                                        }}
                                        style={{
                                            // color: colors.azul_oscuro,
                                            marginTop: "10px",
                                            marginRight: "10px",
                                            backgroundColor: "transparent",
                                            borderColor: "transparent",
                                        }}
                                    >
                                        {this.state.dispositivo.modoVigilante === 1 ?
                                            <ToggleOn
                                                style={{
                                                    width: "50px",
                                                    height: "40px",
                                                    color: colors.azul
                                                }}
                                            />
                                            :
                                            <ToggleOff
                                                style={{
                                                    width: "50px",
                                                    height: "40px",
                                                    color: "grey"
                                                }}
                                            />}
                                    </button>
                                </FormGroup>
                            </Col>
                            <Col
                                md="6"
                            >
                                <FormGroup>
                                    <Label>
                                        Guardar Registros
                                    </Label>
                                    <button

                                        onClick={async () => {
                                            await this.setState({
                                                dispositivo: {
                                                    ...this.state.dispositivo,
                                                    guardarRegistro: this.state.dispositivo.guardarRegistro === 1 ? 0 : 1
                                                }
                                            });

                                        }}
                                        style={{
                                            // color: colors.azul_oscuro,
                                            marginTop: "10px",
                                            marginRight: "10px",
                                            backgroundColor: "transparent",
                                            borderColor: "transparent",
                                        }}
                                    >
                                        {this.state.dispositivo.guardarRegistro === 1 ?
                                            <ToggleOn
                                                style={{
                                                    width: "50px",
                                                    height: "40px",
                                                    color: colors.azul
                                                }}
                                            />
                                            :
                                            <ToggleOff
                                                style={{
                                                    width: "50px",
                                                    height: "40px",
                                                    color: "grey"
                                                }}
                                            />}
                                    </button>
                                </FormGroup>
                            </Col>
                        </Row>
                        {
                            this.state.dispositivo.IdTipo == 2 ?
                                <>
                                    <Row>
                                        <Label>
                                            Rango de temperatura ( {this.state.dispositivo.minTemperatura + "  °C , " + this.state.dispositivo.maxTemperatura + " °C"} )
                                        </Label>
                                    </Row>
                                    <Row>
                                        <Col
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            <Slider

                                                style={{
                                                    width: "100%",
                                                    color: colors.azul,

                                                    // backgroundColor:"blue"
                                                }}
                                                value={[this.state.dispositivo.minTemperatura, this.state.dispositivo.maxTemperatura]}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        dispositivo: {
                                                            ...this.state.dispositivo,
                                                            minTemperatura: newValue[0],
                                                            maxTemperatura: newValue[1],

                                                        }
                                                    });
                                                }}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"

                                            >
                                            </Slider>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label>
                                            Rango de humedad ( {this.state.dispositivo.minHumedad + " % , " + this.state.dispositivo.maxHumedad + " %"} )
                                        </Label>
                                    </Row>
                                    <Row>
                                        <Col
                                            style={{
                                                textAlign: "center"
                                            }}
                                        >
                                            <Slider

                                                style={{
                                                    width: "100%",
                                                    color: colors.azul,

                                                    // backgroundColor:"blue"
                                                }}
                                                value={[this.state.dispositivo.minHumedad, this.state.dispositivo.maxHumedad]}
                                                onChange={(event, newValue) => {
                                                    this.setState({
                                                        dispositivo: {
                                                            ...this.state.dispositivo,
                                                            minHumedad: newValue[0],
                                                            maxHumedad: newValue[1],

                                                        }
                                                    });
                                                }}
                                                valueLabelDisplay="auto"
                                                aria-labelledby="range-slider"
                                            >
                                            </Slider>
                                        </Col>
                                    </Row>
                                </>
                                :
                                null
                        }

                        {
                            !this.state.isMobileDevice ?
                                <Row>
                                    <Col>
                                        <abbr
                                            style={{
                                                textDecoration: "none"
                                            }}
                                            title={this.state.dispositivo.statusDispositivo == 5 ? `Conectandose con ${this.state.dispositivo.IdTipo == 2 ? " la chapa" : " el dispositivo"}...` : this.state.dispositivo.status}
                                        >
                                            <Button
                                                style={{
                                                    backgroundColor: (this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? "gray" : colors.azul,
                                                    marginRight: "15px"
                                                }}
                                                onClick={() => {
                                                    let dispositivo = {
                                                        origen: 1,
                                                        IdDispositivo: this.state.dispositivo.Id,
                                                        IdChapa: this.state.dispositivo.Id,
                                                        IdStatus: 7,
                                                    }
                                                    if (this.state.dispositivo.IdTipo == 1) {
                                                        // chapa
                                                        chapaModel.chapa_cambiar_status(dispositivo);

                                                    } else {
                                                        // dispositivo
                                                        dispositivoModel.dispositvo_cambiar_status(dispositivo);
                                                    }
                                                }}
                                                disabled={(this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? true : false}
                                            >
                                                <RotateLeft
                                                    style={{
                                                        marginRight: "5px"
                                                    }}
                                                />
                                                Reiniciar {this.state.dispositivo.IdTipo == 1 ? "chapa" : " dispositivo"}
                                            </Button>
                                            {
                                                this.state.dispositivo.IdTipo == 1 ?
                                                    <>
                                                        <Button
                                                            style={{
                                                                width: "165px",
                                                                backgroundColor: (this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? "gray" : colors.azul,
                                                                marginRight: "15px"
                                                            }}
                                                            disabled={(this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? true : false}
                                                            onClick={() => {
                                                                swal2.fire({
                                                                    icon: 'question',
                                                                    title: `¿Abrir puesta?`,
                                                                    text: `¿Estas seguro de que quieres abrir la puesta de manera remota?`,
                                                                    timer: "10000",
                                                                    confirmButtonColor: '#016390',
                                                                    confirmButtonText: 'Si, abrir',
                                                                    cancelButtonText: "No abrir",
                                                                    allowEscapeKey: true,
                                                                    showCancelButton: true,
                                                                    reverseButtons: true,
                                                                    timerProgressBar: true,
                                                                    //footer : '<p style="color:'+colors.azul_oscuro+';"  >No se recuoeraran los datos anteriores</p>',
                                                                }).then(async (response) => {
                                                                    if (response.isConfirmed) {
                                                                        let cahpa = {
                                                                            origen: 1,
                                                                            IdChapa: this.state.dispositivo.Id,
                                                                            IdStatus: 8,
                                                                        }
                                                                        chapaModel.chapa_cambiar_status(cahpa);
                                                                    }
                                                                });
                                                            }}
                                                        >
                                                            <MeetingRoom
                                                                style={{
                                                                    marginRight: "5px"
                                                                }}
                                                            />
                                                            Abrir puerta
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                width: "165px",
                                                                backgroundColor: (this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? "gray" : colors.azul,
                                                                marginRight: "15px"
                                                            }}
                                                            disabled={(this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? true : false}
                                                            onClick={async () => {
                                                                // let cahpa = await {
                                                                //     origen: 1,
                                                                //     IdChapa: this.state.dispositivo.Id,
                                                                //     IdStatus: 10,
                                                                // }
                                                                // chapaModel.chapa_cambiar_status(cahpa);
                                                                let data = await chapaModel.chapa_check_espacio_acceso(this.state.dispositivo.Id);
                                                                // console.log(data);
                                                                if (data.count == 0) {
                                                                    swal2.fire({
                                                                        icon: 'warning',
                                                                        title: `Espacios ocupados`,
                                                                        text: `Se alcanso el maximo de huellas guardades el la chapa, ya no se puede agregar mas huellas`,
                                                                        // timer: "10000",
                                                                        confirmButtonColor: '#016390',
                                                                        confirmButtonText: 'Okey',
                                                                        // cancelButtonText: "No abrir",
                                                                        allowEscapeKey: true,
                                                                        // showCancelButton: true,
                                                                        reverseButtons: true,
                                                                        timerProgressBar: true,
                                                                        footer: '<p style="color:' + colors.azul_oscuro + ';">Puede quitar huellas para poder agregar mas despues</p>',
                                                                    });
                                                                } else {
                                                                    // this.listarUsuarios();
                                                                    let data = await userModel.usuarios_posibles_enrolar_chapa(this.state.dispositivo.Id)
                                                                    // console.log(data);
                                                                    let dataListUsuarios = data.userList;
                                                                    this.setState({
                                                                        openModalListUsuarios: true,
                                                                        refTable: 1,
                                                                        dataListUsuarios
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <Fingerprint
                                                                style={{
                                                                    marginRight: "5px"
                                                                }}
                                                            />
                                                            Agregar huella
                                                        </Button>
                                                        <Button
                                                            style={{
                                                                backgroundColor: colors.azul,
                                                                marginRight: "15px"
                                                            }}
                                                            onClick={async () => {
                                                                let data = await userModel.usuario_enrolados_chapa(this.state.dispositivo.Id);
                                                                // console.log(data.listUsuarios);
                                                                let dataListUsuarios = data.listUsuarios;
                                                                await this.setState({
                                                                    openModalHuellasEnroladas: true,
                                                                    dataListUsuarios,
                                                                    refTable: 2,
                                                                });
                                                            }}
                                                        >
                                                            <Group
                                                                style={{
                                                                    marginRight: "5px"
                                                                }}
                                                            />
                                                            Huellas enroladas
                                                        </Button>
                                                    </>
                                                    :
                                                    null

                                            }

                                        </abbr>
                                        {this.state.dispositivo.IdTipo == 2 ?
                                            <Button
                                                style={{
                                                    backgroundColor: (this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? "gray" : colors.azul,
                                                }}
                                                onClick={async () => {
                                                    let dispositivo = {
                                                        origen: 1,
                                                        IdDispositivo: this.state.dispositivo.Id,
                                                        IdChapa: this.state.dispositivo.Id,
                                                        IdStatus: 8,
                                                    }
                                                    dispositivoModel.dispositvo_cambiar_status(dispositivo);
                                                    this.setState({
                                                        ultimaLecturaActiva: false,
                                                    });
                                                }
                                                }
                                                disabled={(this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? true : false}
                                            >
                                                <SettingsRemote
                                                    style={{
                                                        marginRight: "5px"
                                                    }}
                                                />
                                                Obtener lectura actual
                                            </Button>
                                            :
                                            null
                                        }
                                        {
                                            this.state.ultimaLecturaActiva ?
                                                <abbr
                                                    // title={}
                                                    style={{
                                                        textDecoration: "none",
                                                        marginRight: "15px"
                                                    }}
                                                >
                                                    <Label
                                                        style={{
                                                            marginLeft: "15px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        {date.format_date(this.state.ultimaLectura.fecha) + " : " + time.format_am_pm(this.state.ultimaLectura.hora) + "   " + this.state.ultimaLectura.temperatura + " ºC, " + this.state.ultimaLectura.humedad + " %"}
                                                    </Label>
                                                </abbr>

                                                :
                                                null
                                        }

                                    </Col>
                                </Row>
                                :
                                <>
                                    <Row>
                                        <Col>
                                            <Button
                                                style={{
                                                    backgroundColor: (this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? "gray" : colors.azul,
                                                    width: "100%",
                                                }}
                                                onClick={() => {
                                                    let dispositivo = {
                                                        origen: 1,
                                                        IdDispositivo: this.state.dispositivo.Id,
                                                        IdChapa: this.state.dispositivo.Id,
                                                        IdStatus: 7,
                                                    }
                                                    if (this.state.dispositivo.IdTipo == 1) {
                                                        // chapa
                                                        chapaModel.chapa_cambiar_status(dispositivo);

                                                    } else {
                                                        // dispositivo
                                                        dispositivoModel.dispositvo_cambiar_status(dispositivo);
                                                    }
                                                }}
                                                disabled={(this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? true : false}
                                            >
                                                <RotateLeft
                                                    style={{
                                                        marginRight: "5px"
                                                    }}
                                                />
                                                Reiniciar {this.state.dispositivo.IdTipo == 1 ? "chapa" : " dispositivo"}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: "15px",
                                        }}
                                    >
                                        <Col>
                                            <Button
                                                style={{
                                                    backgroundColor: (this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? "gray" : colors.azul,
                                                    width: "100%",
                                                }}
                                                disabled={(this.state.dispositivo.statusDispositivo == 5) || (this.state.dispositivo.statusDispositivo == 7) || (this.state.dispositivo.statusDispositivo == 0) ? true : false}
                                                onClick={() => {
                                                    swal2.fire({
                                                        icon: 'question',
                                                        title: `¿Abrir puesta?`,
                                                        text: `¿Estas seguro de que quieres abrir la puesta de manera remota?`,
                                                        timer: "10000",
                                                        confirmButtonColor: '#016390',
                                                        confirmButtonText: 'Si, abrir',
                                                        cancelButtonText: "No abrir",
                                                        allowEscapeKey: true,
                                                        showCancelButton: true,
                                                        reverseButtons: true,
                                                        timerProgressBar: true,
                                                        //footer : '<p style="color:'+colors.azul_oscuro+';"  >No se recuoeraran los datos anteriores</p>',
                                                    }).then(async (response) => {
                                                        if (response.isConfirmed) {
                                                            let cahpa = {
                                                                origen: 1,
                                                                IdChapa: this.state.dispositivo.Id,
                                                                IdStatus: 8,
                                                            }
                                                            chapaModel.chapa_cambiar_status(cahpa);
                                                        }
                                                    });
                                                }}
                                            >
                                                Abrir puerta
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row
                                        style={{
                                            marginTop: "15px"
                                        }}
                                    >
                                        <Col>
                                            <Button
                                                style={{
                                                    width: "100%",
                                                    backgroundColor: colors.azul,
                                                }}
                                            >
                                                Agregar huella
                                            </Button>
                                        </Col>
                                    </Row>
                                </>
                        }


                    </ModalBody>
                    <ModalFooter
                        style={{
                            backgroundColor: colors.blanco,
                        }}
                    >
                        <Button
                            style={{
                                width: "100px",
                            }}
                            onClick={() => {
                                this.setState({
                                    openModal: false,
                                    dispositivo: {
                                        Id: 0,
                                        codigo: "",
                                        tipo: "",
                                        IdTipo: 0,
                                        ubicacion: "",
                                        descripcionUbicacion: "",
                                        modoVigilante: 0,
                                        statusDispositivo: -1,
                                    }
                                });
                            }}
                        >
                            Cerrar
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                                width: "100px",
                            }}
                            onClick={() => {
                                this.guardarDispositivoChapa();

                            }}
                        >
                            Guardar
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.openModalListUsuarios}
                    size="xl"
                >

                    <ModalHeader
                        style={{
                            backgroundColor: colors.azul_oscuro,
                            color: colors.blanco
                        }}
                    >
                        {
                            this.state.isLoasTableUsuario ?
                                "Cargando tabla de dispostivos..."
                                :
                                "Tabla de dispostivos"
                        }
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col>
                                <Label
                                    style={{
                                        fontSize: "24px"
                                    }}
                                >
                                    {
                                        this.state.usuarioSeleccionado.isSelect ?
                                            "Usuario selecionado"
                                            :
                                            "Selecciona un usuario"
                                    }
                                </Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    {/* <Label>
                                        Id Usuario
                                    </Label>
                                    <br /> */}
                                    {/* <Label
                                        type="text"
                                        value={this.state.usuarioSeleccionado.idusuarios}
                                        disabled
                                    >
                                        {this.state.usuarioSeleccionado.idusuarios}
                                    </Label> */}
                                    <TextField
                                        label="Id Usuario"
                                        // variant="outlined"
                                        startAdornme
                                        name="username"
                                        style={{
                                            width: "90%",
                                            marginLeft: "2%",
                                            color: "red",
                                        }}
                                        value={this.state.usuarioSeleccionado.idusuarios}
                                    // onChange={(e) => {
                                    //     this.handleChangeUsuario(e);
                                    // }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    {/* <Label>
                                        Nombre completo
                                    </Label> */}
                                    {/* <Input
                                        type="text"
                                        value={this.state.usuarioSeleccionado.nombreCompleto}
                                        disabled
                                    /> */}
                                    <TextField
                                        label="Nombre completo"
                                        // variant="outlined"
                                        startAdornme
                                        name="username"
                                        style={{
                                            width: "90%",
                                            marginLeft: "2%",
                                            color: "red",
                                        }}
                                        value={this.state.usuarioSeleccionado.nombreCompleto}
                                        disabled
                                    // onChange={(e) => {
                                    //     this.handleChangeUsuario(e);
                                    // }}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    {/* <Label>
                                        Nombre de Usuario
                                    </Label>
                                    <Input
                                        type="text"
                                        value={this.state.usuarioSeleccionado.nombreUsuario}
                                        disabled
                                    /> */}
                                    <TextField
                                        label="Nombre de Usuario"
                                        // variant="outlined"
                                        startAdornme
                                        name="username"
                                        style={{
                                            width: "90%",
                                            marginLeft: "2%",
                                            color: "red",
                                        }}
                                        value={this.state.usuarioSeleccionado.nombreUsuario}
                                    // onChange={(e) => {
                                    //     this.handleChangeUsuario(e);
                                    // }}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Table
                                columns={this.state.dataColumdUsuario}
                                data={this.state.dataListUsuarios}
                                title={
                                    <Label style={{
                                        fontSize: "24px"
                                    }}>
                                        Tabla de Usuarios
                                    </Label>}
                                noDataComponent={<p style={{ fontWeight: "bold" }}>Cargando tabla</p>}
                                pagination
                                dense
                            />
                        </Row>

                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                width: "100px"
                            }}
                            onClick={async () => {
                                await this.setState({
                                    openModalListUsuarios: false,
                                    usuarioSeleccionado: {
                                        isSelect: false,
                                        idusuarios: "",
                                        nombreCompleto: "",
                                        nombreUsuario: "",
                                    }
                                });
                            }}
                        >
                            {
                                !this.state.usuarioSeleccionado.isSelect ?
                                    "Cerrar"
                                    :
                                    "Cancelar"
                            }
                        </Button>
                        <Button
                            style={{
                                width: "100px",
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                            }}
                            disabled={!this.state.usuarioSeleccionado.isSelect}
                            onClick={async () => {

                                await this.setState({
                                    haySusuarioSeleccionado: true,
                                    openModalListUsuarios: false,
                                });
                                await chapaModel.selecionar_IdHulla_usuario({
                                    IdChapa: this.state.dispositivo.Id,
                                    IdUsuario: this.state.usuarioSeleccionado.idusuarios
                                });
                                let cahpa = await {
                                    origen: 1,
                                    IdChapa: this.state.dispositivo.Id,
                                    IdStatus: 10,
                                }
                                await chapaModel.chapa_cambiar_status(cahpa);
                            }}
                        >
                            Seleccionar
                        </Button>
                    </ModalFooter>

                </Modal>
                <Modal
                    isOpen={this.state.openModalHuellasEnroladas}
                    size="xl"
                >
                    <ModalHeader
                        style={{
                            backgroundColor: colors.azul_oscuro,
                            color: colors.blanco
                        }}
                    >
                        Huellas de usuarios enrrolados a esta chapa
                    </ModalHeader>

                    <ModalBody>
                        <Row>
                            <Col
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                <abbr
                                    style={{
                                        textDecoration: "none"
                                    }}
                                    title="Actualizar tabla"
                                >
                                    <IconButton
                                        style={{
                                            marginRight: "25px"
                                        }}
                                        onClick={async () => {
                                            let data = await userModel.usuario_enrolados_chapa(this.state.dispositivo.Id);
                                            // console.log(data.listUsuarios);
                                            let dataListUsuarios = data.listUsuarios;
                                            await this.setState({
                                                openModalHuellasEnroladas: true,
                                                dataListUsuarios,
                                                refTable: 2,
                                            });
                                        }}
                                    >
                                        <Cached
                                            style={{
                                                color: colors.azul,
                                            }}
                                        />
                                    </IconButton>
                                </abbr>
                            </Col>
                        </Row>
                        <Table
                            columns={this.state.dataColumdUsuario}
                            data={this.state.dataListUsuarios}
                            title={
                                <Label style={{
                                    fontSize: "24px"
                                }}>
                                    Tabla de usuario enrolados
                                </Label>}
                            noDataComponent={<p style={{ fontWeight: "bold" }}>{this.state.dataListUsuarios.length == 0 ? "No hay usuarios con acceso a esta chapa" : ""}</p>}
                            pagination
                            dense
                        />
                    </ModalBody>

                    <ModalFooter>
                        <Button
                            style={{
                                // width: "200px",
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                            }}
                            onClick={() => {
                                this.setState({
                                    openModalHuellasEnroladas: false,
                                });
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

}

export default TableDispositivos;