import axios from 'axios';
import swal2 from 'sweetalert2';

const mensajeError = (error) => {

    swal2.fire({
        icon: 'error',
        title: 'Error de Red',
        text: error,
        //timer: "5000",
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true
    }).then(() => {
        //window.location.href="/";
    });

}

const format_am_pm = (time) => {

    time = time + ""

    let referencia = "AM"

    let horas = parseInt(time.substring(0, 2));

    let minutos = time.substring(3, 5);

    let segundos = time.substring(6, 8);


    if (horas > 12) {
        horas = horas - 12



        referencia = "PM";
    }

    if (horas < 10) {
        horas = "0" + horas;
    }


    let _time = horas + ":" + minutos + ":" + segundos + " " + referencia;



    return _time;
}
const getFecha = async () => {
    try {
        let response = await axios({
            method: "GET",
            url: "https://worldtimeapi.org/api/timezone/America/Mexico_City",
        });
        let date = response.data.datetime.substring(0, 10);
        return date;
        // return  "2023-01-19"
    } catch (error) {
        mensajeError(error);
        return  "2023-01-01"
    }
}

const getHora = async () => {
    try {
        let response = await axios({
            method: "GET",
            url: "https://worldtimeapi.org/api/timezone/America/Mexico_City",
        });
        let time = response.data.datetime.substring(11, 19);
        return time;
        // return  "12:00:00"
    } catch (error) {
        mensajeError(error);
        return  "12:00:00"
    }

}

const restarSumatHoras = (hora, diferencia) =>{
    let horas = parseInt(hora.substring(0,2));
    let minutos = hora.substring(3,5);
    let segundos = hora.substring(6,8);
    // console.log(hora);
    horas = horas + diferencia;
    // console.log(horas);

    if(horas > -1 && horas < 10){
        horas = "0"+horas
    }
    if(horas <= 0){
        return "00:00:00"
    }
   

    if(horas > 24){ 
        horas = 23;
    }


    let resultado = horas + ":" + minutos+ ":" + segundos
    // console.log(resultado);
    return resultado
}

const formatoFecha = (fecha) =>{

    let ano = fecha.substring(0,4);
    let mes = fecha.substring(5,7);
    let dia = fecha.substring(8,10);

    return dia +"/"+ mes + "/" + ano;


    return 
}

const timeModel = {
    format_am_pm,
    getFecha,
    getHora,
    restarSumatHoras,
    formatoFecha
}

export default timeModel;