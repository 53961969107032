import { Component } from "react";
import NavBar from "../components/NavBar";
import colors from "../configuration/colors.config"
import { Chart } from "react-google-charts";
import dispositivoModel from "../models/dispositivo.model";
import swal2 from 'sweetalert2';
import Table from "react-data-table-component";
import IconButton from "@material-ui/core/IconButton";
import TextField from '@material-ui/core/TextField';
import {
    Button,
    Input,
    Row,
    Col,
    Label,
    FormGroup,
    ModalFooter,
    ModalBody,
    ModalHeader,
    Modal,
} from "reactstrap"
// import TyHMiddleware from "../middleware/TyH.middleware"
import time from "../models/time.model";
import TyHModel from "../models/TyH.model";
import { Toast } from "../models/Toast.model";
import timeModel from "../models/time.model";
import date from "../models/date.model";
import {
    Cached,
    Check,
    FormatListBulleted,
    SignalCellularNull
} from '@material-ui/icons';
import firebase from 'firebase';
var database = firebase.database();

let cFechaRegistros = "";

const optionsLineal = {

    title: "Temperatura y Humedad ",
    // curveType: "function",
    intervals: { 'lineWidth': 2, 'barWidth': 0.5, style: 'boxes' },
    series: {
        0: {
            type: 'linear',
            color: '#E02100',
            lineWidth: 2,
            opacity: 1,
            showR2: true,
            visibleInLegend: true,

        },
        1: {
            type: 'linear',
            color: '#0E5A9B',
            lineWidth: 2,
            opacity: 1,
            showR2: true,
            visibleInLegend: true
        },

    },
    hAxis: {
        title: "Hora de registro",
    },
    vAxis: {
        title: "Temperatura / Humeda",
    },
    animation: {
        duration: 1000,
        easing: 'out',
    },

};

const optionsBarra = {
    chart: {
        title: "Temperatura y Humedad",
        subtitle: "Maximos y Minimos, primadio de cada dia",
    },
    animation: {
        duration: 1000,
        easing: 'out',
    },
    colors: ['#F26D55', '#E02100', '#168DF5', '#0E5A9B']
};

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mostrarTodosRegistros: false,
            ultimaLecturaFechaHora: "",
            device: {
                ubicacion: "",
                descripcionUbicacion: "",
                codigo: "",
                modoVigilante: -1,
                fechaRegistros: "",
                primeraHora: "",
                segundaHora: "",
            },
            dataColumnDevice: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Codigo</p>,
                    selector: row => row.codigo,
                    sortable: true,
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Ubicacion</p>,
                    selector: row => row.ubicacion,
                    sortable: true,
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Descripcion ubicacion</p>,
                    selector: row => row.descripcionUbicacion,
                    sortable: true,
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Accion</p>,
                    selector: row =>
                        <IconButton
                            onClick={async () => {
                                let fechaRegistros = await timeModel.getFecha();
                                let segundaHora = await timeModel.getHora();
                                let primeraHora = timeModel.restarSumatHoras(segundaHora, -3);
                                row = {
                                    ...row,
                                    fechaRegistros,
                                    segundaHora,
                                    primeraHora
                                }
                                await this.setState({
                                    isOpenModalDevice: false,
                                    device: row,
                                    fechaReferenciaBarra: fechaRegistros
                                });
                                cFechaRegistros = fechaRegistros
                                await this.obtenerRegistrosTyH(row);
                                await this.obtenerMaximosYMinimos(row);
                            }}
                        >
                            <Check
                                style={{
                                    color: colors.verdeSuccess,
                                }}
                            />
                        </IconButton>,
                    sortable: true,
                },
            ],
            listDivice: [],
            isOpenModalDevice: true,
            colorsUltimaLectura: {
                temperatura: "#00F200",
                humedad: "#00F200"
            },
            ultimaLectura: {
                temperatura: 0,
                humedad: 0,
                fecha: "",
                hoea: "",
            },
            fecha: "",
            isMobileDevice: false,
            fechaReferenciaBarra: "",
            dataLineal: [
                [
                    { id: 'i0', label: "Fecha" },
                    { id: '0', type: "number", label: "Temperatura", },
                    { id: 'i2', type: "number", label: "Humedad", },
                ],
                [
                    "",
                    0,
                    0,
                ]
            ],
            dataBarra: [
                ["Fecha", "Temperatura minim", "Temperatura maxima", "Hmedad minima", "Humedad maxima"],
                ["000-00-00", 0, 0, 0, 0]
            ],
        }
    }

    componentDidMount() {

        document.body.style.backgroundColor = colors.azul;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        this.setState({
            isMobileDevice: isMobileDevice,
        });

        this.cargarDataPge();

    }

    cargarDataPge = async () => {
        
        let data = await dispositivoModel.dispositivos_list();

        let listDivice = data.dataList;
        await this.setState({
            listDivice,
        });

        database.ref(`tablaTyH/`).on('value', async (snapshot) => {

            if (snapshot.exists()) {
                if (this.state.device.codigo.length > 0) {
                    let segundaHora = await timeModel.getHora();
                    console.log(segundaHora);
                    await this.setState({
                        device: {
                            ...this.state.device,
                            segundaHora
                        }
                    });
                    await this.obtenerRegistrosTyH(this.state.device);
                    await this.obtenerMaximosYMinimos(this.state.device);
                }

            }


        });

        database.ref(`ultimaLecturaTyh/`).on('value', async (snapshot) => {

            if (snapshot.exists()) {
                let fecha = await timeModel.getFecha();
                let ultimaLecturaFechaHora = 
                    <abbr
                        style={{
                            textDecoration:"none",                            
                        }}
                        title={date.format_date_string(fecha)}
                    >
                        { time.formatoFecha(fecha) + " a las " + timeModel.format_am_pm(await timeModel.getHora())}
                    </abbr>;
                this.setState({
                    ultimaLectura: snapshot.val(),
                    ultimaLecturaFechaHora
                });

                this.setState({
                    colorsUltimaLectura: {
                        temperatura: "#00F200",
                        humedad: "#00F200"
                    }
                });
            }
        });


    }

    obtenerMaximosYMinimos = (device) => {

        TyHModel.registro_tyh_max_min(device, (data) => {

            let rows = data.data;

            let dataBarra = [["Fecha", "Temperatura minim", "Temperatura maxima", "Hmedad minima", "Humedad maxima"]]

            if (rows.length >= 2) {

                // if(rows.length < 6){
                //     Toast.fire({
                //         icon:"warning",
                //         text:`Solo hay registros ${rows.length -1 }`
                //     });
                // }

                rows.reverse();

                for (let i = 0; i < rows.length; i++) {

                    if (rows[i].fecha !== null) {
                        dataBarra.push([
                            date.format_date(rows[i].fecha.substring(0, 10)),
                            rows[i].temperaturaMin,
                            rows[i].temperaturaMax,
                            rows[i].humedadMin,
                            rows[i].humedadMax
                        ])
                    }

                }

                this.setState({
                    dataBarra
                });
            } else {
                Toast.fire({
                    icon: "warning",
                    text: `No hay registros de esta fecha ${this.state.fechaReferenciaBarra}`,
                    timer: 3000
                });
            }



        })
    }

    obtenerRegistrosTyH = async (device) => {

        if (device.codigo.length == 0) {
            return;
        }
        // console.log("Obteniendo registros");
        TyHModel.TyH_registros(device, (response) => {

            let data = response.data;
            let dataLineal = [
                [
                    { id: 'i0', label: "Fecha" },
                    { id: 'i1', type: "number", label: "Temperatura", },
                    { id: 'i2', type: "number", label: "Humedad", },
                ],
            ];
            let fecha = "";
            if (data.length === 0) {
                dataLineal.push(
                    [
                        "",
                        0,
                        0,
                    ]);
                Toast.fire({
                    icon: "warning",
                    title: `No se encontraron resgistros en la fecha (${this.state.device.fechaRegistros}) entre las ${this.state.device.primeraHora} y las ${this.state.device.segundaHora}`
                });
            } else {

                this.setState({
                    ultimaLectura: {
                        ...this.state.ultimaLectura,
                        fecha: data[data.length - 1].fecha,
                        hora: data[data.length - 1].hora
                    }
                });
                for (let i = 0; i < data.length; i++) {
                    let { temperatura, humedad, hora, } = data[i]
                    dataLineal.push(
                        [
                            timeModel.format_am_pm(hora),
                            temperatura,
                            humedad,

                        ]
                    );

                }


            }
            this.setState({
                dataLineal,
                fecha
            });

        });
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} />
                <div
                    style={{
                        marginTop: this.state.isMobileDevice ? "15px" : "80px",
                        marginLeft: this.state.isMobileDevice ? "5px" : "1%",
                        marginRight: this.state.isMobileDevice ? "5px" : "1%",
                        textAlign: "right",
                        backgroundColor: colors.blanco,
                        height: "60px",
                        borderRadius: "8px",
                    }}
                >
                    <Button

                        onClick={async () => {
                            this.setState({
                                isOpenModalDevice: true,
                            });
                        }}
                        style={{
                            color: colors.blanco,
                            marginTop: "10px",
                            marginRight: "10px",
                            backgroundColor: colors.azul_oscuro,
                            borderColor: colors.azul_oscuro,
                        }}
                    >
                        <FormatListBulleted />
                    </Button>
                    <Button
                        id="btnCached"
                        onClick={async () => {
                            await this.obtenerRegistrosTyH(this.state.device);
                            await this.obtenerMaximosYMinimos(this.state.device);
                        }}
                        style={{
                            color: colors.blanco,
                            marginTop: "10px",
                            marginRight: "10px",
                            backgroundColor: colors.azul_oscuro,
                            borderColor: colors.azul_oscuro,
                        }}
                    >
                        <Cached />
                    </Button>
                </div>
                <div
                    style={{
                        marginTop: "10px",
                        marginLeft: this.state.isMobileDevice ? "5px" : "1%",
                        marginRight: this.state.isMobileDevice ? "5px" : "1%",
                        //     0textAlign: "right",
                        borderRadius: "8px",
                        backgroundColor: colors.blanco
                    }}
                >
                    <Row
                        style={{
                            marginLeft: this.state.isMobileDevice ? "10px" : "5%",

                        }}
                    >
                        <Row
                            style={{
                                marginTop: "20px",
                            }}
                        >
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "18px"
                                }}
                            >
                                Dispositivo Selecionado
                            </Label>
                        </Row>
                        <Row>
                            <Col
                                md="3"
                            >
                                <FormGroup>
                                    <TextField
                                        label="Ubicacion"
                                        // variant="outlined"
                                        startAdornme
                                        name="username"
                                        style={{
                                            width: "90%",
                                            marginLeft: "2%",
                                            color: "red",
                                        }}
                                        value={this.state.device.ubicacion}
                                    />
                                </FormGroup>
                            </Col>
                            <Col
                                md="3"
                            >
                                <FormGroup>
                                    <TextField
                                        label="Descripcion Ubicacion"
                                        // variant="outlined"
                                        startAdornme
                                        name="username"
                                        style={{
                                            width: "90%",
                                            marginLeft: "2%",
                                            color: "red",
                                        }}
                                        value={this.state.device.descripcionUbicacion}
                                    />
                                </FormGroup>
                            </Col>
                            <Col
                                md="3"
                            >
                                <TextField
                                    label="Codigo"
                                    // variant="outlined"
                                    startAdornme
                                    name="username"
                                    style={{
                                        width: "90%",
                                        marginLeft: "2%",

                                    }}
                                    value={this.state.device.codigo}
                                />
                            </Col>
                            <Col
                                md="3"
                            >
                                <TextField
                                    label="Modo Vigilante"
                                    // variant="outlined"
                                    startAdornme
                                    name="username"
                                    style={{
                                        width: "90%",
                                        marginLeft: "2%",
                                        color: "red",
                                    }}
                                    value={this.state.device.modoVigilante == -1 ? "" : this.state.device.modoVigilante == 1 ? "Activado" : "Desactivado"}
                                />
                            </Col>
                        </Row>
                    </Row>
                    <Row
                        style={{
                            marginLeft: this.state.isMobileDevice ? "10px" : "5%",
                            marginTop: "12px"
                        }}
                    >
                        <Col>
                            <Label
                                style={{
                                    fontWeight: "bold",
                                    fontSize: "18px"
                                }}
                            >
                                Filtrar por
                            </Label>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginLeft: this.state.isMobileDevice ? "10px" : "5%",
                            marginTop: "12px"
                        }}
                    >
                        <Col>
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        // fontSize: "18px",
                                        marginRight: "7px"
                                    }}
                                >
                                    Mostrar todos los registros de hoy
                                    {/* <br /> */}

                                </Label>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        // fontSize: "18px",
                                        marginRight: "15px"
                                    }}
                                >
                                    {timeModel.formatoFecha(this.state.device.fechaRegistros)}
                                </Label>
                                <Input
                                    style={{
                                        // width: this.state.isMobileDevice ? "90%" : "250px",
                                    }}
                                    type="checkbox"
                                    value={this.state.mostrarTodosRegistros}
                                    onClick={async (e) => {
                                        let { value } = e.target;
                                        let primeraHora, segundaHora;
                                        segundaHora = await timeModel.getHora();
                                        if (!this.state.mostrarTodosRegistros) {
                                            primeraHora = "00:00:00";
                                        } else {
                                            primeraHora = timeModel.restarSumatHoras(segundaHora, -3);
                                        }
                                        console.log(primeraHora, segundaHora);
                                        await this.setState({
                                            device: {
                                                ...this.state.device,
                                                primeraHora,
                                                segundaHora
                                            },
                                            mostrarTodosRegistros: !this.state.mostrarTodosRegistros
                                        });

                                        await this.obtenerRegistrosTyH({
                                            IdDispositivo: this.state.device.IdDispositivo,
                                            codigo: this.state.device.codigo,
                                            fechaRegistros: this.state.device.fechaRegistros,
                                            primeraHora,
                                            segundaHora,
                                        });



                                    }}
                                />

                            </FormGroup>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginLeft: this.state.isMobileDevice ? "10px" : "5%",
                            marginTop: "12px"
                        }}
                    >
                        <Col
                            md="3"
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        // fontSize: "18px"
                                    }}
                                >
                                    Fecha de los registros
                                </Label>
                                <Input
                                    style={{
                                        width: this.state.isMobileDevice ? "90%" : "250px",
                                    }}
                                    id=""
                                    name="fechaRegistros"
                                    max={cFechaRegistros}
                                    placeholder=""
                                    type="date"
                                    value={this.state.device.fechaRegistros}
                                    onChange={async (e) => {
                                        let { value } = e.target;
                                        await this.setState({
                                            device: {
                                                ...this.state.device,
                                                fechaRegistros: value
                                            }
                                        });

                                        await this.obtenerRegistrosTyH(this.state.device);

                                    }}
                                />

                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Primera hora
                                </Label>
                                <Input
                                    disabled={this.state.mostrarTodosRegistros}
                                    style={{
                                        width: this.state.isMobileDevice ? "90%" : "250px",
                                    }}
                                    type="time"
                                    value={this.state.device.primeraHora}
                                    onChange={async (e) => {
                                        let { value } = e.target;

                                        if (value < this.state.device.segundaHora) {
                                            await this.setState({
                                                device: {
                                                    ...this.state.device,
                                                    primeraHora: value
                                                }
                                            });
                                        } else {
                                            Toast.fire({
                                                icon: "warning",
                                                title: "Hora invalida",
                                                text: "La primera hora deve de ser menor a la segunda hora",
                                                timer: 7000
                                            });

                                        }
                                        await this.obtenerRegistrosTyH(this.state.device, 1);

                                    }}
                                />

                            </FormGroup>
                        </Col>
                        <Col
                            md="3"
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Segunda hora
                                </Label>
                                <Input
                                    disabled={this.state.mostrarTodosRegistros}
                                    style={{
                                        width: this.state.isMobileDevice ? "90%" : "250px",
                                    }}
                                    type="time"
                                    step={1}
                                    value={this.state.device.segundaHora}
                                    onChange={async (e) => {
                                        let { value } = e.target;
                                        if (value > this.state.device.primeraHora) {
                                            await this.setState({
                                                device: {
                                                    ...this.state.device,
                                                    segundaHora: value,
                                                }
                                            });
                                        } else {
                                            Toast.fire({
                                                icon: "warning",
                                                title: "Hora invalida",
                                                text: "La segunda hora deve de ser mayor a la primera hora",
                                                timer: 7000
                                            });
                                        }
                                        await this.obtenerRegistrosTyH(this.state.device, 1);

                                    }}
                                />

                            </FormGroup>
                        </Col>

                    </Row>
                    <Row
                        style={{
                            marginLeft: this.state.isMobileDevice ? "10px" : "5%",
                            marginTop: "12px"
                        }}
                    >
                        <Col
                            style={{
                                // textAlign: "right",
                                // marginLeft: this.state.isMobileDevice ? "10px" : "5%",
                                // marginRight: this.state.isMobileDevice ? "10px" : "10%",
                                // marginTop: "12px",
                            }}
                        // md="8"
                        >
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "18px"
                                    }}
                                >
                                    Ultima lectura
                                </Label>
                                <br />
                                <Label
                                    style={{
                                        // color: this.state.colorsUltimaLectura.humedad,
                                        fontWeight: "bold",
                                        // fontSize: "26px",
                                        marginRight: "25px"
                                    }}
                                >
                                    {this.state.ultimaLectura.temperatura} ºC
                                </Label>
                                <Label
                                    style={{
                                        // color: this.state.colorsUltimaLectura.humedad,
                                        fontWeight: "bold",
                                        // fontSize: "26px",
                                        // marginLeft: "25px"
                                    }}
                                >
                                    {this.state.ultimaLectura.humedad} %
                                </Label>
                                <Label
                                    style={{
                                        // color: this.state.colorsUltimaLectura.humedad,
                                        fontWeight: "bold",
                                        // fontSize: "26px",
                                        marginLeft: "25px"
                                    }}
                                >
                                    {this.state.ultimaLecturaFechaHora}
                                </Label>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div
                        style={{
                            borderRadius: "8px",
                            borderColor: 'red'
                        }}
                    >
                        <Chart
                            chartType="LineChart"
                            width="99%"
                            height="400px"
                            data={this.state.dataLineal}
                            options={optionsLineal}
                            style={{
                                borderRadius: "8px",
                                borderColor: 'red',
                                backgroundColor: "transparent"
                            }}
                        />
                    </div>

                </div>

                <div
                    style={{
                        marginTop: "10px",
                        marginLeft: this.state.isMobileDevice ? "5px" : "1%",
                        marginRight: this.state.isMobileDevice ? "5px" : "1%",
                        //     0textAlign: "right",
                        borderRadius: "8px",
                        backgroundColor: colors.blanco
                    }}
                >
                    <Row
                        style={{
                            marginLeft: this.state.isMobileDevice ? "10px" : "5%",
                        }}
                    >
                        <Col>
                            <FormGroup>
                                <Label
                                    style={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Fecha del del ultimo dia
                                </Label>
                                <Input
                                    style={{
                                        width: this.state.isMobileDevice ? "90%" : "250px",
                                    }}
                                    id=""
                                    name="fechaRegistros"
                                    placeholder=""
                                    type="date"
                                    max={cFechaRegistros}
                                    value={this.state.fechaReferenciaBarra}
                                    onChange={async (e) => {
                                        let { value } = e.target;
                                        await this.setState({
                                            fechaReferenciaBarra: value,
                                        });

                                        await this.obtenerMaximosYMinimos({
                                            IdDispositivo: this.state.device.IdDispositivo,
                                            fechaRegistros: value
                                        });

                                    }}
                                />

                            </FormGroup>

                        </Col>
                    </Row>
                    <div
                        style={{
                            marginTop: "10px",
                            marginLeft: this.state.isMobileDevice ? "10px" : "5%",
                            marginRight: this.state.isMobileDevice ? "10px" : "5%",
                            //     0textAlign: "right",
                            borderRadius: "8px",
                            backgroundColor: colors.blanco
                        }}
                    >
                        <Chart
                            chartType="Bar"
                            width="99%"
                            height="400px"
                            data={this.state.dataBarra}
                            options={optionsBarra}
                        />
                    </div>
                </div>

                <Modal
                    isOpen={this.state.isOpenModalDevice}
                    size="xl"
                >
                    <ModalHeader
                        style={{
                            backgroundColor: colors.azul_oscuro,
                            color: colors.blanco
                        }}
                    >
                        Seleccione un dispositivo
                    </ModalHeader>
                    <ModalBody
                        style={{
                            height: "500px",
                            overflowY: "scroll"
                        }}
                    >
                        <Row>
                            <Col
                                style={{
                                    textAlign: "right"
                                }}
                            >
                                <IconButton
                                    title="Refrescar tabla"
                                    onClick={async () => {
                                        let data = await dispositivoModel.dispositivos_list();

                                        let listDivice = data.dataList;
                                        await this.setState({
                                            listDivice,
                                        });

                                    }}
                                >
                                    <Cached />
                                </IconButton>
                            </Col>

                        </Row>
                        <Row>

                            <Table
                                columns={this.state.dataColumnDevice}
                                data={this.state.listDivice}
                                title={<Label stle={{ fontSize: "24px", color: colors.negro }}>Tabla de Dispositivos</Label>}
                                noDataComponent={<p style={{ fontWeight: "bold" }}></p>}
                                // pagination
                                dense
                            />
                        </Row>
                    </ModalBody>
                    <ModalFooter>

                        <Button
                            onClick={() => {
                                this.setState({
                                    isOpenModalDevice: false
                                });
                            }}
                        >
                            Cerrar
                        </Button>

                    </ModalFooter>
                </Modal>
            </>
        );
    }

}

export default Main;