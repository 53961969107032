import md5 from "md5";

const cifrarPassword = (password) =>{

    return md5("?*" + password + "@#");

}


const user = {
    cifrarPassword
}

export default user;