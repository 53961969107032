import axios from 'axios';
import API from '../network/API'
import swal2 from 'sweetalert2';

const mensajeError = (error) => {

    swal2.fire({
        icon: 'error',
        title: 'Error de Red',
        text: error,
        //timer: "5000",
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true
    }).then(() => {
        //window.location.href="/";
    });
    
}

const TyH_registros = (device,callback) => {
    // console.log(device);
    axios({
        method: "POST",
        url: API + "/TyH/all_registros",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data : {
            device,
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const registro_tyh_max_min = (device,callback) => {
    // console.log(device);
    axios({
        method: "POST",
        url: API + "/TyH/registro_tyh_max_min",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data : {
            device
        }
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const TyHModel = {
    TyH_registros,
    registro_tyh_max_min,
}

export default TyHModel;