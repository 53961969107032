import axios from 'axios';
import API from '../network/API'
import swal2 from 'sweetalert2';

const mensajeError = (error) => {

    swal2.fire({
        icon: 'error',
        title: 'Error de Red',
        text: error,
        //timer: "5000",
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true
    }).then(() => {
        //window.location.href="/";
    });

}


const chapa_list = async () => {
    try {
        let registros = await axios({
            method: "GET",
            url: API + "/chapa/chapa_list",
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
        });
        return registros.data;
    } catch (error) {
        mensajeError(error);

    }


}

const chapa_cambiar_status = async (chapa) => {
    let registros = await axios({
        method: "PUT",
        url: API + "/chapa/chapa_cambiar_status",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            chapa
        }
    });
    return registros.data;

}

const chapa_porId = async (IdChapa) => {
    let registros = await axios({
        method: "GET",
        url: API + `/chapa/chapa_porId/${IdChapa}`,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },

    });
    return registros.data;

}

const chapa_guardar = async (chapa) => {
    let registros = await axios({
        method: "PUT",
        url: API + "/chapa/chapa_guardar",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            chapa,
        }
    });
    return registros.data;

}

const chapa_check_espacio_acceso = async (IdChapa) => {
    try {
        let response = await axios({
            method: "GET",
            url: API + "/chapa/chapa_check_espacio_acceso/" + IdChapa,
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
        });
        return response.data;
    } catch (error) {
        mensajeError(error);

    }

}

const dispositivo_ver_numero_paso = (callback) => {
    //// console.log(fecha);
    axios({
        method: "GET",
        url: API + "/dispositivos/dispositivo_ver_numero_paso",
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const pasos_nueva_guella_agregar_paso = (numeroPaso, callback) => {
    //// console.log(fecha);
    axios({
        method: "GET",
        url: API + "/dispositivos/pasos_nueva_guella_agregar_paso/" + numeroPaso,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
    }).then(response => {
        let data = response.data;

        callback(data);

    }).catch((error) => {
        mensajeError(error);
    });
}

const selecionar_IdHulla_usuario = async (data) => {
    try {
        let response = await axios({
            method: "POST",
            url: API + "/chapa/selecionar_IdHulla_usuario/",
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
            data: {
                data
            }
        });
        return response.data

    } catch (error) {
        mensajeError(error);

    }

}

const entradas_list = async (chapa) => {
    try {
        // await sleep(3);
        let response = await axios({
            method: "POST",
            url: API + "/chapa/entradas_list/",
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
            data: {
                chapa
            }
        });
        return response.data

    } catch (error) {
        mensajeError(error);

    }

}
const sleep = (seconds) => {
    return new Promise(resolve => setTimeout(resolve, (seconds * 1000)));
}
const chapaModel = {
    chapa_list,
    chapa_guardar,
    chapa_check_espacio_acceso,
    dispositivo_ver_numero_paso,
    pasos_nueva_guella_agregar_paso,
    chapa_cambiar_status,
    chapa_porId,
    selecionar_IdHulla_usuario,
    entradas_list,
}

export default chapaModel;