import axios from 'axios';
import API from '../network/API'
import swal2 from 'sweetalert2';



const mensajeError = (error) => {

    swal2.fire({
        icon: 'error',
        title: 'Error de Red',
        text: "No se pudo conectar con el servidor",
        //timer: "5000",
        confirmButtonColor: '#016390',
        confirmButtonText: 'Okey',
        allowEscapeKey: true,
        timerProgressBar: true,    
        footer: `<label>${error}</label>`    
    }).then(() => {
        //window.location.href="/";
    });

}

const dispositivos_chapa_list = async () => {
    try {
        let registros = await axios({
            method: "GET",
            url: API + "/dispositivo/dispositivos_chapa_list",
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
        });
        return registros.data;
    } catch (error) {
        mensajeError(error);
    }

}

const dispositivos_list = async () => {
    try {
        let registros = await axios({
            method: "GET",
            url: API + "/dispositivo/dispositivos_list",
            headers: {
                //token : cookies.get('token')
                "Access-Control-Allow-Origin": "*"
            },
        });
        return registros.data;
    } catch (error) {
        mensajeError(error);
    }


}

const dispositivo_porId = async (IdDispositivo) => {
    let registros = await axios({
        method: "GET",
        url: API + `/dispositivo/dispositivo_porId/${IdDispositivo}`,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
    });
    return registros.data;

}

const dispositvo_cambiar_status = async (dispositivo) => {
    // console.log("Cambiando status dispositivo");
    // console.log(API + `/dispositivo/dispositvo_cambiar_status`);
    // console.log(dispositivo);
    let registros = await axios({
        method: "PUT",
        url: API + `/dispositivo/dispositvo_cambiar_status`,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            dispositivo
        }
    });
    return registros.data;

}

const dispositivo_guardar = async (dispositivo) => {
    let registros = await axios({
        method: "PUT",
        url: API + `/dispositivo/dispositivo_guardar`,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            dispositivo
        }
    });
    return registros.data;

}

const dispositivo_obtener_ultima_lectura = async (IdDispositivo) => {
    let registros = await axios({
        method: "GET",
        url: API + `/dispositivo/dispositivo_obtener_ultima_lectura/${IdDispositivo}`,
        headers: {
            //token : cookies.get('token')
            "Access-Control-Allow-Origin": "*"
        },
        data: {
            // dispositivo
        }
    });


    let {data} = registros;

    // console.log(data);

    return data;

}


const dispositivoModel = {
    dispositivos_chapa_list,
    dispositivos_list,
    dispositivo_porId,
    dispositvo_cambiar_status,
    dispositivo_guardar,
    dispositivo_obtener_ultima_lectura
}

export default dispositivoModel;