import { Component } from "react";
import NavBar from "../components/NavBar";
import colors from "../configuration/colors.config"
import Table from "react-data-table-component";
import gifLoading from "../multimedia/gif/loading.gif";
import swal2 from 'sweetalert2';
import dispositivoModel from "../models/chapa.model";
import IconButton from "@material-ui/core/IconButton";
import {
    Button,
    Row,
    Col,
    Label,
    FormGroup,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    DropdownItem,
    Spinner
} from "reactstrap";
import {
    Cached,
    Edit,
    ToggleOn,
    ToggleOff,
    Delete,
    Add,
    Visibility
} from '@material-ui/icons';
import { Toast } from "../models/Toast.model";
import date from "../models/date.model";

import userModel from "../models/user.model";
import firebase from 'firebase';

var database = firebase.database();

class TableDispositivos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ciclo: true,
            pasosPermitirAcceso: 0,
            statusNotificar: {
                text: {
                    0: "No",
                    1: "Si",
                },
                colors: {
                    0: "#546067",
                    1: "#8CDD07",
                },
            },
            dataColumd: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Id Usuario</p>,
                    selector: row => row.idusuarios,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombre completo</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.nombre + " " + row.paterno + " " + row.materno} >{row.nombre + " " + row.paterno + " " + row.materno}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Fecha de Nacimiento</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={date.format_date_string(row.fechaNacimiento)} >{date.format_date(row.fechaNacimiento)}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Nombre de usuario</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.username} >{row.username}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Direccion</p>,
                    selector: row =>
                        <abbr
                            style={{ textDecoration: "none" }}
                            title={row.direccion}
                        >
                            <label>
                                {row.direccion}
                            </label>
                        </abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Correo</p>,
                    selector: row =>
                        <abbr
                            style={{ textDecoration: "none" }}
                            title={row.correo}
                        >
                            <label>
                                {row.correo}
                            </label>
                        </abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Notificar</p>,
                    selector: row =>
                        <label
                            style={{
                                fontWeight: "bold",
                                color: this.state.statusNotificar.colors[row.notificar]
                            }}
                        >
                            {this.state.statusNotificar.text[row.notificar]}
                        </label>,
                    sortable: true
                },
                // {
                //     name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Tiene permiso de acceso</p>,
                //     selector: row =>
                //         <label
                //             style={{
                //                 fontWeight: "bold",
                //                 color: this.state.statusNotificar.colors[row.tieneAcceso ? 1 : 0]
                //             }}
                //         >
                //             {row.tieneAcceso ? "Si" : "No"}
                //             {row.tieneAcceso}
                //         </label>,
                //     sortable: true
                // },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Acciones</p>,
                    selector: row =>
                        <>
                            <IconButton
                                onClick={() => {
                                    console.log(row);
                                    this.setState({
                                        usuario: row,
                                        openModal: true,
                                    });
                                }}
                            >
                                <Visibility
                                    style={{
                                        color: colors.azul,
                                        // backgroundColor : colors.blanco
                                    }}
                                />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    this.setState({
                                        usuario: row,
                                        // openModal: true,
                                    });
                                    this.eliminarUsuario();
                                }}
                            >
                                <Delete
                                    style={{
                                        color: colors.rojo,
                                        // backgroundColor : colors.blanco
                                    }}
                                />
                            </IconButton>
                        </>
                    ,
                    sortable: true
                },

            ],
            usuario: {
                idusuarios: 0,
                nombre: "",
                paterno: "",
                materno: "",
                fechaNacimiento: "2000-01-01",
                username: "",
                password: "",
                direccion: "",
                correo: "",
                notificar: 0,
            },
            dataList: [],
            isMobileDevice: false,
            openModal: false,
            openModalAcceso: false,
            isTableLoad: true,
        }
    }

    componentDidMount() {

        document.body.style.backgroundColor = colors.azul;

        document.body.style.backgroundColor = colors.azul;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);

        this.setState({
            isMobileDevice: isMobileDevice,
        });

        this.cargarDataPge();

    }

    cargarDataPge = () => {


        database.ref(`pasos_nueva_huella/`).on('value', (snapshot) => {

            if (snapshot.exists()) {

                // let numero = dispositivoMiddleware.desencript(snapshot.val().pasoNumero);

                if (snapshot.val().pasoNumero == 0) {
                    this.setState({
                        pasosPermitirAcceso: 0
                    })
                } else {
                    this.setState({
                        pasosPermitirAcceso: snapshot.val().pasoNumero
                    })
                }

            }

        });

        this.listarUsuarios();
    }

    listarUsuarios = async () => {
        let data = await userModel.usuario_list_por_acceso_dispositivo();
        if (data == undefined) {
            this.setState({
                isTableLoad: false
            });
        } else {
            let dataList = data.users;
            for (let i = 0; i < dataList.length; i++) {
                console.log(dataList[i].fechaNacimiento);
                dataList[i].fechaNacimiento = dataList[i].fechaNacimiento.substring(0, 10);
                console.log(dataList[i].fechaNacimiento);
            }
            this.setState({
                dataList,
                isTableLoad: false
            });
            // Toast.fire({
            //     icon: "success",
            //     title: "Tabla cargada"
            // });
        }
    }

    guardarUsuario = () => {



        swal2.fire({
            icon: 'question',
            title: `${this.state.usuario.idusuarios === 0 ? "Guardar" : "Actualizar "} Usuario`,
            text: `¿Estas seguro de que quieres ${this.state.usuario.idusuarios === 0 ? "guardar" : "actualizar "} este usuario con estos datos?`,
            timer: "10000",
            confirmButtonColor: '#016390',
            confirmButtonText: `Si, ${this.state.usuario.idusuarios === 0 ? "Guardar" : "Actualizar "}`,
            cancelButtonText: "Cerrar",
            allowEscapeKey: true,
            showCancelButton: true,
            reverseButtons: true,
            timerProgressBar: true,
            //footer : '<p style="color:'+colors.azul_oscuro+';"  >No se recuoeraran los datos anteriores</p>',
        }).then((response) => {
            if (response.isConfirmed) {
                if (this.state.usuario.idusuarios === 0) {
                    userModel.usuario_crear(this.state.usuario, (data) => {
                        Toast.fire({
                            icon: "success",
                            title: "Usuario guardado"
                        });
                        this.setState({
                            // openModal: false,
                            usuario: {
                                ...this.state.usuario,
                                idusuarios: data.user.idusuarios
                            }
                        });
                        this.listarUsuarios();
                    });
                } else {
                    userModel.usuario_actualizar(this.state.usuario, (data) => {
                        Toast.fire({
                            icon: "success",
                            title: "Usuario actualizado"
                        });
                        this.setState({
                            // openModal: false,
                            // usuario: {
                            //     idusuarios: 0,
                            //     nombre: "",
                            //     paterno: "",
                            //     materno: "",
                            //     fechaNacimiento: "2000-01-01",
                            //     username: "",
                            //     password: "",
                            //     direccion: "",
                            //     correo: "",
                            //     notificar: 0,
                            // },
                        });
                        this.listarUsuarios();
                    });
                }

            }
        });

    }

    handleChangeUsuario = (e) => {
        let { name, value } = e.target;

        this.setState({
            usuario: {
                ...this.state.usuario,
                [name]: value
            }
        })
    }

    eliminarUsuario = () => {
        swal2.fire({
            icon: 'question',
            title: `Eliminar Usuario`,
            text: `¿Estas seguro de que quieres eliminar al usuario ${this.state.usuario.username}`,
            timer: "10000",
            confirmButtonColor: colors.rojo,
            confirmButtonText: `Si, eliminar`,
            cancelButtonText: "Cerrar",
            allowEscapeKey: true,
            showCancelButton: true,
            reverseButtons: true,
            timerProgressBar: true,
            footer: '<p style="color:' + colors.azul_oscuro + ';"  >No se podra recuperar al usuario una ves eliminado</p>',
        }).then((response) => {
            if (response.isConfirmed) {
                userModel.usuario_eliminar(this.state.usuario.idusuarios, (data) => {
                    Toast.fire({
                        icon: "success",
                        title: "Usuario eliminado"
                    });
                    this.listarUsuarios();
                });
            }
        });
    }

    validandoDatos = async () => {
        await dispositivoModel.dispositivo_check_espacio_acceso(this.state.usuario.idusuarios, async (data) => {
            if (data.count > 0) {
                this.setState({
                    // openModalAcceso: false,
                    // openModal: false,
                    // pasosPermitirAcceso: this.state.pasosPermitirAcceso + 1,
                });
            } else {

            }

        });
    }

    agregarPaso = (numeroPaso) => {
        dispositivoModel.pasos_nueva_guella_agregar_paso(numeroPaso, (data) => { });
    }

    sleep(seconds) {
        return new Promise(resolve => setTimeout(resolve, (seconds * 1000)));
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} />
                <Row
                    style={{
                        marginTop: this.state.isMobileDevice ? "15px" : "100px",
                        marginLeft: this.state.isMobileDevice ? "5px" : "2%",
                        marginRight: this.state.isMobileDevice ? "5px" : "2%",
                        backgroundColor: colors.blanco,
                        height: "60px",
                        borderRadius: "8px",
                    }}
                >

                    <Col>
                        {
                            this.state.isTableLoad ?
                                <Label
                                    style={{
                                        marginTop: "15px",
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                        color: colors.azul_oscuro
                                    }}
                                >
                                    {this.state.isMobileDevice ?
                                        "Cargando..."
                                        :
                                        "Cargando datos, espere por favor..."
                                    }

                                </Label>
                                :
                                null
                        }

                    </Col>
                    <Col
                        style={{
                            textAlign: "right",
                        }}
                    >
                        <Button

                            onClick={async () => {
                                if (!this.state.isTableLoad) {
                                    await this.setState({
                                        openModal: true,
                                        usuario: {
                                            idusuarios: 0,
                                            nombre: "",
                                            paterno: "",
                                            materno: "",
                                            fechaNacimiento: "2000-01-01",
                                            username: "",
                                            password: "",
                                            direccion: "",
                                            correo: "",
                                            notificar: 0,
                                        },
                                    });
                                }

                            }}
                            style={{
                                color: colors.blanco,
                                marginTop: "10px",
                                marginRight: "10px",
                                backgroundColor: colors.azul_oscuro,
                                borderColor: colors.azul_oscuro,
                            }}
                        >{
                                this.state.isTableLoad ?
                                    <Spinner
                                        style={{
                                            marginTop: "",
                                            height: '1.4rem',
                                            width: '1.4rem'
                                            // fontSize:"25px"
                                        }}
                                    />
                                    :
                                    <Add />
                            }
                        </Button>
                        <Button

                            onClick={async () => {
                                if (!this.state.isTableLoad) {
                                    await this.setState({
                                        isTableLoad: true
                                    })
                                    await this.cargarDataPge();


                                }

                            }}
                            style={{
                                color: colors.blanco,
                                marginTop: "10px",
                                marginRight: "10px",
                                backgroundColor: colors.azul_oscuro,
                                borderColor: colors.azul_oscuro,
                            }}
                        >
                            {
                                this.state.isTableLoad ?
                                    <Spinner
                                        style={{
                                            marginTop: "",
                                            height: '1.4rem',
                                            width: '1.4rem'
                                            // fontSize:"25px"
                                        }}
                                    />
                                    :
                                    <Cached />
                            }
                        </Button>
                    </Col>

                </Row>

                <div
                    style={{
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginTop: this.state.isMobileDevice ? "20px" : "10px",
                        height: "90%",
                    }}
                >
                    <div
                        style={{
                            borderRadius: "8px",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }}
                    >
                        {
                            this.state.isTableLoad ?
                                <Row
                                    style={{
                                        marginLeft: this.state.isMobileDevice ? "0px" : "0px",
                                        marginRight: this.state.isMobileDevice ? "0px" : "0px",
                                        backgroundColor: colors.blanco,
                                        // height: "80px",
                                        borderRadius: "0px 0px 8px 8px",
                                    }}
                                >
                                    <Col
                                        style={{
                                            textAlign: "center"
                                        }}
                                    >
                                        <img
                                            style={{
                                                marginTop: "30px",
                                                marginBottom: "50px"
                                            }}
                                            src={gifLoading}
                                            height={this.state.isMobileDevice ? 100 : 400}
                                        />
                                    </Col>
                                </Row>
                                :
                                <Table
                                    columns={this.state.dataColumd}
                                    data={this.state.dataList}
                                    title={"Tabla de Usuarios"}
                                    noDataComponent={<p style={{ fontWeight: "bold" }}>Cargando tabla</p>}
                                    pagination
                                    dense
                                />
                        }

                    </div>
                </div>
                <Row><Col></Col></Row>
                <Modal
                    isOpen={this.state.openModal}
                    size="xl"
                    style={{
                        marginTop: "1%"
                    }}
                >
                    <ModalHeader
                        style={{
                            backgroundColor: colors.azul_oscuro,
                            color: colors.blanco
                        }}
                    >
                        {this.state.usuario.idusuarios === 0 ? "Guardar Usuario" : `Actualizar Usuario #${this.state.usuario.idusuarios}`}
                    </ModalHeader>
                    <ModalBody
                        style={{
                            // backgroundColor: colors.azul,
                            // color: colors.blanco
                        }}
                    >


                        <Row>
                            <Col
                                md={4}
                            >
                                <FormGroup>
                                    <Label>
                                        Nombre
                                    </Label>
                                    <Input
                                        name="nombre"
                                        type="text"
                                        onChange={(e) => {
                                            this.handleChangeUsuario(e);
                                        }}
                                        value={this.state.usuario.nombre}
                                    />
                                </FormGroup>
                            </Col>
                            <Col
                                md={4}
                            >
                                <FormGroup>
                                    <Label>
                                        Apellido Paterno
                                    </Label>
                                    <Input
                                        name="paterno"
                                        type="text"
                                        onChange={(e) => {
                                            this.handleChangeUsuario(e);
                                        }}
                                        value={this.state.usuario.paterno}
                                    />
                                </FormGroup>
                            </Col>
                            <Col
                                md={4}
                            >
                                <FormGroup>
                                    <Label>
                                        Apellido Materno
                                    </Label>
                                    <Input
                                        name="materno"
                                        type="text"
                                        onChange={(e) => {
                                            this.handleChangeUsuario(e);
                                        }}
                                        value={this.state.usuario.materno}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col
                                md={4}
                            >
                                <FormGroup>
                                    <Label>
                                        Fecha de Nacimiento {this.state.usuario.fechaNacimiento}
                                    </Label>
                                    <Input
                                        name="fechaNacimiento"
                                        type="date"
                                        onChange={(e) => {
                                            this.handleChangeUsuario(e);
                                        }}
                                        value={this.state.usuario.fechaNacimiento}
                                    />
                                </FormGroup>
                            </Col>
                            <Col
                                md={4}
                            >
                                <FormGroup>
                                    <Label>
                                        Nombre de usuario
                                    </Label>
                                    <Input
                                        name="username"
                                        type="text"
                                        onChange={(e) => {
                                            this.handleChangeUsuario(e);
                                        }}
                                        value={this.state.usuario.username}
                                    />
                                </FormGroup>
                            </Col>
                            {
                                this.state.usuario.idusuarios === 0 ?
                                    <Col
                                        md={4}
                                    >
                                        <FormGroup>
                                            <Label>
                                                Contraseña del usuario
                                            </Label>
                                            <Input
                                                name="password"
                                                type="text"
                                                onChange={(e) => {
                                                    this.handleChangeUsuario(e);
                                                }}
                                                value={this.state.usuario.password}
                                            />
                                        </FormGroup>
                                    </Col>
                                    :
                                    null
                            }

                        </Row>
                        <Row>
                            {/* <Col
                                md={4}
                            >
                                <FormGroup>
                                    <Label>
                                        Correo
                                    </Label>
                                    <Input
                                        name="correo"
                                        type="text"
                                        onChange={(e) => {
                                            this.handleChangeUsuario(e);
                                        }}
                                        value={this.state.usuario.correo}
                                    />
                                </FormGroup>
                            </Col> */}
                            <Col
                                md={4}
                            >
                                <FormGroup>
                                    <Label>
                                        Correo
                                    </Label>
                                    <Input
                                        name="correo"
                                        type="text"
                                        onChange={(e) => {
                                            this.handleChangeUsuario(e);
                                        }}
                                        value={this.state.usuario.correo}
                                    />
                                </FormGroup>
                            </Col>
                            <Col
                                md={4}
                            >
                                <FormGroup
                                    style={{
                                        // marginTop:"100px"
                                    }}
                                >
                                    <Row>
                                        <Label
                                            onClick={async () => {
                                                // alert(this.state.usuario.correo, this.state.usuario.correo.length);
                                                if (this.state.usuario.correo.length == 0) {
                                                    await Toast.fire({
                                                        icon: "error",
                                                        title: "Correo no valido",
                                                        text: "Esta cuenta de correo no es valida, verificala",
                                                        timer: "5000"
                                                    });
                                                } else {
                                                    await this.setState({
                                                        usuario: {
                                                            ...this.state.usuario,
                                                            notificar: this.state.usuario.notificar === 1 ? 0 : 1,
                                                        }

                                                    });
                                                }

                                            }}
                                        >
                                            Notificar
                                        </Label>
                                    </Row>
                                    <Row>
                                        <abbr
                                            style={{
                                                textDecoration: "none",
                                            }}

                                            title={this.state.usuario.notificar === 1 ? "Se notificara por correo al movimiento" : "No se notificara por correo al movimiento"}
                                        >
                                            <button

                                                onClick={async () => {
                                                    if (this.state.usuario.correo.length == 0) {
                                                        await Toast.fire({
                                                            icon: "error",
                                                            title: "Correo no valido",
                                                            text: "Esta cuenta de correo no es valida, verificala",
                                                            timer: "5000"
                                                        });
                                                    } else {
                                                        await this.setState({
                                                            usuario: {
                                                                ...this.state.usuario,
                                                                notificar: this.state.usuario.notificar === 1 ? 0 : 1,
                                                            }

                                                        });
                                                    }
                                                }}
                                                style={{
                                                    // color: colors.azul_oscuro,
                                                    // marginTop: "10px",
                                                    // marginRight: "10px",
                                                    backgroundColor: "transparent",
                                                    borderColor: "transparent",
                                                    // marginTop: "-50px"
                                                }}
                                            >
                                                {
                                                    this.state.usuario.notificar === 1 ?
                                                        <ToggleOn
                                                            style={{
                                                                width: "50px",
                                                                height: "40px",
                                                                color: colors.azul
                                                            }}
                                                        />
                                                        :
                                                        <ToggleOff
                                                            style={{
                                                                width: "50px",
                                                                height: "40px",
                                                                color: "grey"
                                                            }}
                                                        />
                                                }
                                            </button>
                                        </abbr>
                                    </Row>
                                </FormGroup>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>
                                        Direccion
                                    </Label>
                                    <Input
                                        name="direccion"
                                        type="textarea"
                                        onChange={(e) => {
                                            this.handleChangeUsuario(e);
                                        }}
                                        value={this.state.usuario.direccion}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter
                        style={{
                            backgroundColor: colors.blanco,
                        }}
                    >

                        <Button
                            style={{
                                // backgroundColor: colors.gris,
                                width: "100px"
                            }}
                            onClick={() => {
                                this.setState({
                                    openModal: false,
                                    usuario: {
                                        idusuarios: 0,
                                        nombre: "",
                                        paterno: "",
                                        materno: "",
                                        fechaNacimiento: "2000-01-01",
                                        username: "",
                                        password: "",
                                        direccion: "",
                                        correo: "",
                                        notificar: 0,
                                    },
                                });
                            }}
                        >
                            Cerrar
                        </Button>
                        {/* {
                            this.state.usuario.idusuarios === 0 ?
                                null
                                :
                                <Button
                                    style={{
                                        backgroundColor: colors.azul,
                                        color: colors.blanco,
                                        width: "125px"
                                    }}
                                    onClick={async () => {

                                        if (await this.state.usuario.tieneAcceso == 1) {

                                            await userModel.usuario_negar_acceso(this.state.usuario.idusuarios, async (data) => {
                                                Toast.fire({
                                                    icon: "success",
                                                    title: data.menssage,
                                                });
                                                if (data.status == 200) {

                                                    await this.setState({
                                                        ...this.state.usuario,
                                                        tieneAcceso: 0,
                                                    });
                                                    await this.cargarDataPge();
                                                }

                                            });
                                        } else {
                                            await this.setState({
                                                openModalAcceso: true,
                                            });
                                            await this.validandoDatos();

                                        }

                                    }}
                                >
                                    {this.state.usuario.tieneAcceso == 1 ? "Quitar acceso" : "Dar acceso"}
                                </Button>
                        } */}
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                                width: "100px"
                            }}
                            onClick={() => {
                                this.guardarUsuario();

                            }}
                        >
                            {this.state.usuario.idusuarios === 0 ? "Guardar" : "Actualizar"}
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.openModalAcceso}
                    size="lg"
                    style={{
                        marginTop: "10%"
                    }}
                >
                    <ModalHeader>
                        Permitir Acceso {this.state.pasosPermitirAcceso}
                        {/* {this.state.usuario.username} : {this.state.usuario.nombre} {this.state.usuario.paterno} {this.state.usuario.materno} */}
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <Row>
                                    <Label>Nombre</Label>
                                </Row>
                                <Row>
                                    <Label>{this.state.usuario.username}</Label>
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Label>Usuario</Label>
                                </Row>
                                <Row>
                                    <Label>{this.state.usuario.nombre} {this.state.usuario.paterno} {this.state.usuario.materno}</Label>
                                </Row>
                            </Col>
                        </Row>
                        <DropdownItem divider />
                        <Row>
                            <Col>

                                {
                                    this.state.pasosPermitirAcceso == 0 ?
                                        <Row>
                                            <Label>Validando datos</Label>
                                        </Row>
                                        :
                                        null
                                }

                                {
                                    this.state.pasosPermitirAcceso == 1 ?
                                        <Row>
                                            <Label>Conectandose con el dispositivo</Label>
                                        </Row>
                                        :
                                        null
                                }

                                {
                                    this.state.pasosPermitirAcceso == 2 ?
                                        <Row>
                                            <Label>Dispositivo conectado</Label>
                                        </Row>
                                        :
                                        null
                                }

                                {
                                    this.state.pasosPermitirAcceso == 3 ?
                                        <Row>
                                            <Label>Pon tu dedo en el dispositivo 1/2</Label>
                                        </Row>
                                        :
                                        null
                                }

                                {
                                    this.state.pasosPermitirAcceso == 4 ?
                                        <Row>
                                            <Label>Quita el dedo</Label>
                                        </Row>
                                        :
                                        null
                                }
                                {
                                    this.state.pasosPermitirAcceso == 5 ?
                                        <Row>
                                            <Label>Pon de nuevo tu dedo en el dispositivo 2/2</Label>
                                        </Row>
                                        :
                                        null
                                }

                                {
                                    this.state.pasosPermitirAcceso == 6 ?
                                        <Row>
                                            <Label>Huella agregada</Label>
                                        </Row>
                                        :
                                        null
                                }

                                {
                                    this.state.pasosPermitirAcceso == 10 ?
                                        <Row>
                                            <Label>Las huellas dactilares no coinciden</Label>
                                        </Row>
                                        :
                                        null
                                }
                                {
                                    this.state.pasosPermitirAcceso == 11 ?
                                        <Row>
                                            <Label>Error de comunicacion</Label>
                                        </Row>
                                        :
                                        null
                                }

                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button
                            style={{
                                backgroundColor: colors.gris,
                                color: colors.blanco,
                                width: "150px"
                            }}
                            onClick={() => {
                                this.setState({
                                    // openModalAcceso: false,
                                    // openModal: false,
                                    pasosPermitirAcceso: this.state.pasosPermitirAcceso + 1,
                                });
                            }}
                        >
                            Cerrar todo
                        </Button> */}
                        <Button
                            style={{
                                backgroundColor: colors.gris,
                                color: colors.blanco,
                                width: "150px"
                            }}
                            onClick={() => {
                                this.setState({
                                    openModalAcceso: false,
                                    pasosPermitirAcceso: 0,
                                });
                                this.agregarPaso(0);
                            }}
                            disabled={this.state.pasosPermitirAcceso == 6 ? true : false}
                        >
                            Cancelar
                        </Button>
                        <Button
                            style={{
                                backgroundColor: colors.azul,
                                color: colors.blanco,
                                width: "150px"
                            }}
                            onClick={() => {
                                this.setState({
                                    openModalAcceso: false,
                                    usuario: {
                                        ...this.state.usuario,
                                        tieneAcceso: 1,
                                    }
                                });
                                this.agregarPaso(0);
                                this.listarUsuarios();
                            }}
                            disabled={this.state.pasosPermitirAcceso == 6 ? false : true}
                        >
                            Listo
                        </Button>
                    </ModalFooter>
                </Modal>
            </>
        );
    }

}

export default TableDispositivos;