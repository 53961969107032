import { Component } from "react";
import NavBar from "../components/NavBar";
import colors from "../configuration/colors.config"
import Table from "react-data-table-component";
import TextField from '@material-ui/core/TextField';
import swal2 from 'sweetalert2';
import {
    Button,
    Row,
    Col,
    Label,
    FormGroup,
    Input
} from "reactstrap";
import {
    Cached,
    ToggleOn,
    ToggleOff,
} from '@material-ui/icons';
import { Toast } from "../models/Toast.model";
import time from "../models/time.model";
import date from "../models/date.model";
import movimientosModel from "../models/movimisntos.model";
import dispositivoModel from "../models/dispositivo.model";


class TablaMovimientos extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listDispositivo: [],
            fechaRegistros: "",//new Date().toISOString().substring(0, 10),
            mandarCorreo: 0,
            dataColumd: [
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Codigo</p>,
                    selector: row => row.codigo,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Fecha</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={date.format_date_string(row.fecha)} >{date.format_date(row.fecha)}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Hora</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={time.format_am_pm(row.hora)} >{time.format_am_pm(row.hora)}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Ubicacion</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={row.descripcionUbicacion} >{row.ubicacion}</abbr>,
                    sortable: true
                },
                {
                    name: <p style={{ fontWeight: "bold", fontSize: "14px" }}>Descripcion</p>,
                    selector: row => <abbr style={{ textDecoration: "none" }} title={"Ubicacion: " + row.ubicacion} >{row.descripcionUbicacion}</abbr>,
                    sortable: true
                },

            ],
            dataList: [],
            isMobileDevice: false,
            isCheck: true,
            dispositivo: {

            }
        }
    }

    componentDidMount() {
        this.cargarDataPge();
    }

    cargarDataPge = async () => {
        document.body.style.backgroundColor = colors.azul;

        document.body.style.backgroundColor = colors.azul;

        let details = navigator.userAgent;
        let regexp = /android|iphone|kindle|ipad/i;
        let isMobileDevice = regexp.test(details);
        let fechaRegistros = await time.getFecha();
        let listDispositivo = await dispositivoModel.dispositivos_list();
        listDispositivo = listDispositivo.dataList
        console.log(listDispositivo);
        await this.setState({
            fechaRegistros,
            isMobileDevice,
            listDispositivo,
        });
        await this.obtenerListMovimientos(fechaRegistros);
        // await this.obtenerConfiguracionDispositivo();

    }

    obtenerConfiguracionDispositivo = () => {
        movimientosModel.dispositivo_obtener_configuracion(1, (data) => {
            // console.log(data)
            let { IdStatusModiVigilante } = data.dispositivo
            // console.log(IdStatusModiVigilante)
            this.setState({
                mandarCorreo: IdStatusModiVigilante
            })
        })
    }

    obtenerListMovimientos = (fecha) => {
        movimientosModel.movimientos_list(fecha, (data) => {
            // console.log(data);
            let { dataList } = data;
            let { length } = dataList;

            for (let i = 0; i < dataList.length; i++) {
                dataList[i].fecha = dataList[i].fecha.substring(0, 10);
            }

            Toast.fire({
                icon: length === 0 ? "warning" : "success",
                text: length === 0 ? "No se encontraron registros" : `${length} registros encontrados`,
            });

            this.setState({
                dataList,
            });

        });
    }

    render() {
        return (
            <>
                <NavBar isMobileDevice={this.state.isMobileDevice} />
                <Row
                    style={{
                        marginTop: this.state.isMobileDevice ? "15px" : "100px",
                        marginLeft: this.state.isMobileDevice ? "5px" : "2%",
                        marginRight: this.state.isMobileDevice ? "5px" : "2%",
                        backgroundColor: colors.blanco,
                        height: "100px",
                        borderRadius: "8px",
                    }}
                >



                    <Col
                        style={{
                            textAlign: "right",
                        }}
                    >

                        <Button

                            onClick={async () => {
                                await this.cargarDataPge();
                            }}
                            style={{
                                color: colors.blanco,
                                marginTop: "25px",
                                marginRight: "10px",
                                backgroundColor: colors.azul_oscuro,
                                borderColor: colors.azul_oscuro,
                            }}
                        >
                            <Cached />
                        </Button>
                    </Col>

                </Row>

                <div
                    style={{
                        backgroundColor: colors.blanco,
                        borderRadius: "8px",
                        marginLeft: "2%",
                        marginRight: "2%",
                        marginTop: this.state.isMobileDevice ? "20px" : "10px",
                        height: "90%",
                    }}
                >
                    <Row
                        style={{
                            marginLeft:"1%"
                        }}
                    >
                        <Col
                            md="2"
                            style={{
                                textAlign: "left",
                                marginTop: "10px"
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Dispositivo seleccionado
                                </Label>
                                <Input
                                    type="select"
                                    startAdornme
                                    name="fechaRegistros"
                                    style={{
                                        // width: "90%",
                                        // marginLeft: "2%",
                                        // color: "red",
                                    }}
                                    // value={this.state.fechaRegistros}
                                    onChange={(e) => {
                                        let { value } = e.target;


                                    }}
                                >
                                    <option value={0}>-Todos-</option>
                                    {this.state.listDispositivo.map((dispositivo) => {
                                        return (
                                            <option
                                                value={dispositivo.IdDispositivo}
                                                title={dispositivo.ubicacion}
                                            >
                                                {dispositivo.codigo}
                                            </option>
                                        );


                                    })}
                                </Input>
                            </FormGroup>

                        </Col>

                        <Col
                            md="2"
                            style={{
                                textAlign: "left",
                                marginTop: "10px"
                            }}
                        >
                            <FormGroup>
                                <Label>
                                    Fecha de los registros
                                </Label>
                                <Input
                                    label="Fecha"
                                    // variant="outlined"
                                    type="date"
                                    startAdornme
                                    name="fechaRegistros"
                                    style={{
                                        // width: "90%",
                                        // marginLeft: "2%",
                                        // color: "red",
                                    }}
                                    value={this.state.fechaRegistros}
                                    max={this.state.fechaRegistros}
                                    onChange={(e) => {
                                        let { value } = e.target;
                                        //// console.log(value);
                                        this.setState({
                                            fechaRegistros: value,
                                        });

                                        this.obtenerListMovimientos(value);

                                    }}
                                />
                            </FormGroup>

                        </Col>
                    </Row>
                    <div
                        style={{
                            borderRadius: "8px",
                            marginLeft: "10px",
                            marginRight: "10px",
                        }}
                    >
                        <Table
                            columns={this.state.dataColumd}
                            data={this.state.dataList}
                            title={"Tabla de Movimientos"}
                            noDataComponent={<p style={{ fontWeight: "bold" }}>No hay registros guardados</p>}
                            pagination={this.state.isCheck ? true : false}
                            dense
                        />
                    </div>
                </div>
            </>
        );
    }

}

export default TablaMovimientos;