const format_date = (date) => {

    let year = date.substring(0, 4);
    let month = date.substring(5, 7);
    let day = date.substring(8, 10);

    date = day + "/" + month + "/" + year;

    return date;
}

const format_date_string = (date, option) => {


    // console.log(option);


    let meses = [
        "Enero", "Febrero", "Marzo",
        "Abril", "Mayo", "Junio", "Julio",
        "Agosto", "Septiembre", "Octubre",
        "Noviembre", "Diciembre"
    ]

    let  nombreDia = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miércoles',
        'Jueves',
        'Viernes',
        'Sábado',
    ];
    if(option != null){
        if(!option.firstMayus){
       
            nombreDia = [
                'domingo',
                'lunes',
                'martes',
                'miércoles',
                'jueves',
                'viernes',
                'sábado',
            ]
        }
    }



    date = new Date(date);
    let dia = date.getDate()+1;
    let mes = date.getMonth();
    let yyy = date.getFullYear();
    let fff = "";
    if (yyy < 2000) {
        fff = " de "
    } else {
        fff = " del "
    }
    let fecha_formateada = nombreDia[date.getDay()+1] +', '+ dia + ' de ' + meses[mes] + fff  + yyy;
    return fecha_formateada;

}

const date = {
    format_date,
    format_date_string
}

export default date;