

const colors = {
    azul: "#7B61FF",
    azul_oscuro : "#121A2B",
    rosado: "#FF196A",
    gris : "grey",
    blanco:"#FFFFFF",
    amarillo : {
        0 : "#F2DC06",
        1 : "#ECC300",
    },
    rojo : "#F20000",
    negro:"#000",
    verdeSuccess : "#009704",
    naranjaWarning : "#F17600",
}

export default colors 